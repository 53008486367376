/* eslint-disable no-console */
/* eslint-disable max-len */
/**
 *
 * @param {string|URL} url - absolute URL that need to be transformed to relative
 * @param {*} [origin] - If hostname (with protocol) is supplied, only return relative URL for that specific host. Other host return full URL
 * @returns {string} either a relative PATH or same URL as received
 */

const extractRelativePath = (url: string | URL, origin?: string): string => {
  try {
    const myURL = new URL(url)
    // if a hostname is passed, it mean we only want to extract if the URL contain that hostname
    if (origin == null || myURL.origin === origin) {
      return myURL.pathname
    }
    return url.toString()
  } catch (error) {
    console.debug("extractRelativePath: Couldn't extract path from {url}")
    return url.toString()
  }
}

export default extractRelativePath
