import googleTagManager from '@analytics/google-tag-manager'
import Analytics from 'analytics'

const appName = process.env.NEXT_PUBLIC_ANALYTICS_APP_NAME ?? 'NextJS App'
const gtmContainerID = process.env.NEXT_PUBLIC_ANALYTICS_GTM_CONTAINER_ID ?? ''

const analytics = Analytics({
  app: appName,
  debug: true,
  plugins: gtmContainerID
    ? [
        googleTagManager({
          containerId: gtmContainerID,
        }),
      ]
    : undefined,
})

export default analytics
