import type { ProductsQuery } from 'gql/graphql'
import { client, requestHeaders } from 'query'
import { graphql } from '../../../gql'

export const products = graphql(/* GraphQL */ `
  query products($lang: String, $animalType: String) {
    productTypes: product_types {
      id
      translations(filter: { languages_code: { code: { _contains: $lang } } }) {
        title
      }
      availableFacets: available_facets {
        productFacetsId: product_facets_id {
          options {
            translations(filter: { languages_code: { code: { _contains: $lang } } }) {
              name
            }
            id
          }
        }
      }
      products(filter: { animals: { id: { _eq: $animalType } } }, sort: "sort") {
        typename: __typename
        id
        colors: color_scheme {
          background
        }
        featuredImage: featured_image {
          id
          filenameDownload: filename_download
          width
          height
        }
        animals {
          id
          color
          translations(filter: { languages_code: { code: { _contains: $lang } } }) {
            title
          }
        }
        translations(filter: { languages_code: { code: { _contains: $lang } } }) {
          id
          sizes
          subtitle
          title
          slug
        }
        facetOptions: facet_options {
          productFacetsItemId: product_facets_item_id {
            id
          }
        }
      }
    }
  }
`)

async function queryProducts(language?: string, animalType?: string) {
  const data = await client.request<ProductsQuery>(
    products,
    {
      lang: language,
      animalType,
    },
    requestHeaders,
  )

  return data
}

export default queryProducts
