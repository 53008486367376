import { AnimatePresence, motion } from 'framer-motion'
import { useRouter } from 'next/router'
import React from 'react'
import tw, { css } from 'twin.macro'
import { useColor } from '@/providers/Color'

interface PageTransitionProps {
  children: React.ReactNode
  mode?: 'sync' | 'wait' | 'popLayout'
}

const PageTransition = ({ children, mode }: PageTransitionProps) => {
  const router = useRouter()

  const onExitComplete = () => {
    window.scrollTo({ top: 0 })
  }

  const { colorScheme } = useColor()

  return (
    <div
      css={css`
        background-color: ${colorScheme.background};
        ${tw`w-full h-full`}
      `}
    >
      <AnimatePresence mode={mode} onExitComplete={onExitComplete}>
        <motion.div
          key={router.route}
          initial="pageInitial"
          animate="pageAnimate"
          exit="pageExit"
          tw="min-h-min"
          transition={{
            duration: 0.5,
          }}
          variants={{
            pageInitial: {
              opacity: 0,
            },
            pageAnimate: {
              opacity: 1,
            },
            pageExit: {
              opacity: 0,
            },
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default PageTransition
