import { graphql } from 'gql'
import type { ProductsArchivePageQuery } from 'gql/graphql'
import { client, requestHeaders } from 'query'

export const productsArchivePage = graphql(/* GraphQL */ `
  query productsArchivePage($lang: String) {
    pages(filter: { friendly_id: { _eq: "products-archive" } }) {
      colorScheme: color_scheme {
        title
        accent
        background
      }
      translations(filter: { languages_code: { code: { _contains: $lang } } }) {
        title
        seo {
          ...SeoContent
        }
      }
    }
  }
`)

async function queryProductsArchivePage(language?: string) {
  const data = await client.request<ProductsArchivePageQuery>(
    productsArchivePage,
    {
      lang: language,
    },
    requestHeaders,
  )

  return data
}

export default queryProductsArchivePage
