import { useResizeObserver } from '@bedrock-layout/primitives'
import { Img, LottiePlayer, ProductCard, ProductGrid } from '@okam/brand-ui/index'
import { isHex } from '@okam/core-lib/index'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { draw } from 'radash'
import React, { useEffect, useRef, useState } from 'react'
import { toDirectusUrl } from 'lib/directus'
import { QueryKeys } from 'lib/react-query'
import useAnimationsLotties from 'lib/react-query/useAnimationsLotties'
import productLink from 'lib/utils/productLink'
import { useColor } from 'providers/Color'
import { PRODUCT_GRID_DESKTOP_WIDTH_THRESHOLD, PRODUCT_GRID_PRODUCTS_PER_LOTTIE } from '../../../const'
import useArrayOperation from './filters/useArrayOperation'
import type { TProductAndLotties, TProductGridWithLottieProps } from './types'

const ProductGridWithLottie = ({
  products,
  animalType = 'all',
  animalLocation,
  isCardOpen,
  setIsCardOpen,
}: TProductGridWithLottieProps) => {
  const { t } = useTranslation('common')

  const animalRoutes: Record<string, string> = {
    cat: t('URL.CAT_ROUTE'),
    dog: t('URL.DOG_ROUTE'),
  }

  const [productsAndLotties, setProductsAndLotties] = useState<TProductAndLotties[]>([])
  const [containerWidth, setContainerWidth] = useState<number>(0)

  const { data: lottiesData } = useAnimationsLotties(QueryKeys.GetAnimalLotties, true)

  const filteredLotties = useArrayOperation(lottiesData, animalType, {
    type: 'filter',
    filterFunction: (lottie) => lottie?.animal?.id === animalType,
  })

  const randomLotties = useRef<TProductAndLotties[] | null>(null)

  useEffect(() => {
    const calculateLottiesNecessary = (): number => {
      const totalProducts = products?.length ?? 0
      const potential = Math.floor(totalProducts / PRODUCT_GRID_PRODUCTS_PER_LOTTIE)
      const numberOfLotties = potential === 0 ? 1 : potential
      return numberOfLotties
    }

    const randomNecessaryLotties = Array.from({ length: calculateLottiesNecessary() }, () => {
      const randomLottie = draw(filteredLotties ?? [])
      if (randomLottie) {
        const indexOfLottie = filteredLotties?.indexOf(randomLottie)
        filteredLotties?.splice(indexOfLottie ?? 0, 1)
      }
      return randomLottie
    })
    randomLotties.current = randomNecessaryLotties
  }, [filteredLotties, products])

  useEffect(() => {
    const productsTyped = products as TProductAndLotties[]
    const productsCopy = [...productsTyped]
    randomLotties?.current?.forEach((randomLottie, index) => {
      productsCopy?.splice(index * animalLocation - 1 + animalLocation, 0, randomLottie)
    })

    setProductsAndLotties(productsCopy)
  }, [products, animalLocation])

  const ref = useResizeObserver<HTMLDivElement>((entry) => {
    setContainerWidth(entry.contentRect.width)
  })

  const shouldDisplayLottie = (productsLength: number, animationIndex: number) => {
    const randomLottiePotentialNumber = randomLotties?.current?.length ?? 0

    const potentialMaxItems = productsLength + randomLottiePotentialNumber
    const potentialRest = potentialMaxItems % 3

    if (potentialRest === 1) {
      return animationIndex <= randomLottiePotentialNumber - 1 || containerWidth <= PRODUCT_GRID_DESKTOP_WIDTH_THRESHOLD
    }

    return animationIndex <= randomLottiePotentialNumber || containerWidth <= PRODUCT_GRID_DESKTOP_WIDTH_THRESHOLD
  }

  const { colorScheme, setColorScheme } = useColor()

  const handleProductClicked = (id: string, backgroundColor: string) => {
    setIsCardOpen({
      isOpen: true,
      cardOpened: id,
    })
    setColorScheme({
      ...colorScheme,
      background: isHex(backgroundColor) ? backgroundColor ?? '#000000' : '#000000',
    })
  }

  let lottieIndex = 0
  return (
    <div ref={ref} tw="z-[1000]">
      <AnimatePresence>
        <ProductGrid>
          {productsAndLotties?.map((item, index) => {
            const type = item?.typename
            if (type === null || item === null) {
              return null
            }
            switch (type) {
              case 'products':
                return (
                  <ProductCard
                    key={`${item?.featuredImage?.id}-${item?.id}`}
                    id={item?.id ?? ''}
                    onClick={() => handleProductClicked(item?.id ?? '', item?.colors?.background ?? 'white')}
                    isCardOpen={isCardOpen}
                    ariaLabel={item?.translations?.[0]?.title ?? ''}
                    bgColor={item?.colors?.background ?? 'white'}
                    labelColor={item?.animals?.color ?? 'white'}
                    footnote={`${item?.translations?.[0]?.sizes ?? ''}`}
                    title={item?.translations?.[0]?.title ?? ''}
                    link={productLink(
                      animalRoutes[item?.animals?.id ?? ''],
                      item?.translations?.[0]?.slug ?? '',
                      index,
                    )}
                    linkAs={`${animalRoutes[item?.animals?.id ?? '']}/${item?.translations?.[0]?.slug ?? ''}`}
                    labelText={item?.animals?.translations?.[0]?.title ?? ''}
                  >
                    {item?.featuredImage?.id != null && (
                      <Img
                        id={item?.featuredImage?.id}
                        filename={item?.featuredImage.filenameDownload}
                        alt={item?.featuredImage?.filenameDownload ?? ''}
                        fill
                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                      />
                    )}
                  </ProductCard>
                )
              case 'animations':
                lottieIndex += 1
                return shouldDisplayLottie(products?.length ?? 0, lottieIndex) ? (
                  <motion.div
                    layout
                    animate={{
                      opacity: isCardOpen.isOpen ? 0 : 1,
                    }}
                    exit={{
                      opacity: 0,
                    }}
                    initial={{ opacity: 0 }}
                    key={`${item?.lottieFile?.id}`}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: 'auto',
                      height: '100%',
                      backgroundColor: '#e6f2fa',
                    }}
                  >
                    <LottiePlayer
                      src={toDirectusUrl(item?.lottieFile?.id, item?.lottieFile?.filenameDownload) ?? ''}
                      autoplay
                      loop
                      controls={false}
                      hover={false}
                      direction={1}
                      keepLastFrame={false}
                      speed={1}
                      width="100%"
                    />
                  </motion.div>
                ) : null
              default:
                return null
            }
          })}
        </ProductGrid>
      </AnimatePresence>
    </div>
  )
}

export default ProductGridWithLottie
