import tw, { css } from 'twin.macro'
import type { TFoldBackgroundProps } from './interface'

export const getFoldBackgroundStyle = (backgroundColor: TFoldBackgroundProps['backgroundColor']) => css`
  background-color: ${backgroundColor};
  ${tw`
    absolute 
    inset-0
    w-full 
    h-full 
    m-0 
    p-0 
    z-0 
    pointer-events-none
    select-none
  `}
`

export const getWrapperStyle = () => tw`relative mx-auto px-6 2xl:px-10 3xl:px-14 max-w-[1920px]`

const FoldStyle = tw`
  relative 
  block 
  max-w-[100vw] 
  mx-0 
  px-0 
  md:px-0 
  xl:px-0
  overflow-hidden
`

export default FoldStyle
