import { useQuery } from '@tanstack/react-query'
import { useDebounce } from 'usehooks-ts'
import type { ProductsSearchQuery } from 'gql/graphql'
import queryProductsBySearch from 'query/search'

const useProductSearch = (search: string, locale: string, onSuccess?: () => void) => {
  const [searchParams] = useDebounce([search], 1000)

  const productsSearch = useQuery(['search', ...searchParams], () => queryProductsBySearch(searchParams, locale), {
    select: (data: ProductsSearchQuery) => data.productsTranslations,
    staleTime: 1000,
    onSuccess,
    enabled: searchParams !== '',
  })

  return productsSearch
}

export default useProductSearch
