import { motion } from 'framer-motion'
import Link from 'next/link'
import tw, { css } from 'twin.macro'
import Button from '../../Button'
import Heading from '../../Heading'
import Paragraph from '../../Paragraph'
import type { TProductCardProps } from './interface'
import baseStyles, { hoverStyles } from './styles'

const ProductCard = (props: TProductCardProps) => {
  const {
    linkAs,
    id,
    bgColor,
    link,
    children = '',
    title,
    footnote,
    labelColor,
    labelText,
    ariaLabel,
    isCardOpen,
    onClick,
  } = props

  const isActiveCard = isCardOpen.cardOpened === id && isCardOpen.isOpen

  return (
    <div tw="w-full">
      <div tw="w-full h-full">
        <motion.div
          layout
          animate={{
            opacity: isCardOpen.isOpen && !isActiveCard ? 0 : 1,
          }}
          exit={{
            opacity: isActiveCard ? 1 : 0,
          }}
          initial={{ opacity: isActiveCard ? 1 : 0 }}
          css={css`
            ${tw`h-full`}
            ${isActiveCard && tw`fixed top-0 left-0 w-full h-full pointer-events-none z-[1000]`}
          `}
        >
          <Link onClick={onClick} href={link} as={linkAs} scroll={false} aria-label={ariaLabel} passHref>
            <div css={[{ backgroundColor: `${bgColor}` }, baseStyles, hoverStyles, tw`hover:cursor-pointer`]}>
              <motion.div
                animate={{
                  opacity: isActiveCard ? 0 : 1,
                }}
                css={[
                  !isActiveCard,
                  css`
                    ${tw`transition duration-300 p-6 xl:px-8 xl:pt-8 h-full`}
                    ${isActiveCard && tw`flex flex-col justify-between`}
                  `,
                ]}
              >
                <div css={{ backgroundColor: `${labelColor}` }} tw="rounded-full w-max lowercase px-6 py-2">
                  <Heading variant="footnote" tw="font-semibold">
                    {labelText}
                  </Heading>
                </div>
                {children && (
                  <div
                    css={[
                      tw`relative [aspect-ratio: 120/131] max-w-70 mx-auto`,
                      css`
                        img {
                          object-fit: contain;
                        }
                        ${isActiveCard && tw`w-full h-full`}
                      `,
                    ]}
                  >
                    {children}
                  </div>
                )}
                <div tw="w-full flex flex-row -mt-4">
                  <div tw="z-10 mr-4">
                    <Heading variant="h5" as="h3" tw="font-semibold">
                      {title}
                    </Heading>
                    <Paragraph variant="p">{footnote}</Paragraph>
                  </div>
                  <Button
                    tabIndex={-1}
                    aria-hidden="true"
                    variant="iconOnly"
                    icon="ArrowRight"
                    tw="ml-auto h-fit pointer-events-none"
                  />
                </div>
              </motion.div>
            </div>
          </Link>
        </motion.div>
      </div>
    </div>
  )
}

export default ProductCard
