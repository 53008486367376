import { LanguageCodes, type LanguageRoute } from '@/lib/i18n/settings'
import { client, requestHeaders } from 'query'
import { graphql } from '../../gql'

export const settings = graphql(/* GraphQL */ `
  query settings($lang: String) {
    settings {
      storeRocketAccountId: storerocket_account_id
      storeRocketEnabledFilters: storerocket_enabled_filters
      storeRocketFilters: storerocket_filters
      translations(filter: { languages_code: { code: { _eq: $lang } } }) {
        facebookLink: facebook_link
        instagramLink: instagram_link
        address
        privacyPolicy: privacy_policy
        termsAndCondition: terms_and_conditions
        buyCta: buy_cta
        renCta: renspet_cta
        seo {
          ...SeoContent
        }
      }
      mondou_logo {
        id
        filename_download
        description
      }
      renspet_logo {
        id
        filename_download
        description
      }
    }
  }
`)

async function querySettings(language?: string) {
  const lang = language as LanguageRoute
  const languageCode = LanguageCodes[lang]

  const data = client.request(
    settings,
    {
      lang: languageCode,
    },
    requestHeaders,
  )

  return data
}

export default querySettings
