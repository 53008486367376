import type { ImageProps } from 'next/image'
import Image from 'next/image'
import { css } from 'twin.macro'
import type { DirectusImageProps } from './interface'

const imgDomain = process.env.NEXT_PUBLIC_IMG_DOMAIN ?? ''

export const Img = (props: Omit<ImageProps, 'src'> & DirectusImageProps) => {
  const { id, filename, fit, src, customCSS, ...rest } = props

  if (id && filename) {
    const directusImageUrl = new URL(`/assets/${id}/${filename}`, `https://${imgDomain}`)
    directusImageUrl.searchParams.set('fit', fit ?? 'contain')
    const directusImageSrc = directusImageUrl.href

    return (
      <Image
        src={directusImageSrc}
        css={css`
          ${customCSS}
        `}
        {...rest}
      />
    )
  }

  if (src) {
    return (
      <Image
        src={src}
        css={css`
          ${customCSS}
        `}
        {...rest}
      />
    )
  }

  // eslint-disable-next-line no-console
  console.warn('No id and filename or src was provided to the Img component')
  return null
}

export default Img
