import { useQuery } from '@tanstack/react-query'
import type { ArticlesArchivePageQuery } from 'gql/graphql'
import queryArticlesArchivePage from 'query/articles-archive'

const useArticlesArchivePage = (queryKey: string, locale: string) => {
  const articlesArchivePageQuery = useQuery([queryKey], () => queryArticlesArchivePage(locale), {
    select: (data: ArticlesArchivePageQuery) => data.pages[0],
  })

  return articlesArchivePageQuery
}

export default useArticlesArchivePage
