import type { TAddToCartItem } from 'gtm-analytics/events/AddToCart.interface'
import { trackEvent } from './trackEvent'

const trackAddToCart = (item: TAddToCartItem) => {
  trackEvent('addToCart', {
    event: 'ecom_interaction',
    event_name: 'add_to_cart',
    ecommerce: {
      items: [item],
    },
  })
}

export default trackAddToCart
