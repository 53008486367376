import { LanguageCodes, type LanguageRoute } from '@/lib/i18n/settings'
import { client, requestHeaders } from 'query'
import { graphql } from '../../gql'

export const petParentsCornerPage = graphql(/* GraphQL */ `
  query PetParentsCornerPage($lang: String) {
    pages(filter: { friendly_id: { _eq: "pet-parents-corner" } }) {
      friendlyId: friendly_id
      colorScheme: color_scheme {
        ...ColorScheme
      }
      frTranslations: translations(filter: { languages_code: { code: { _eq: "fr-FR" } } }) {
        title
        slug
        id
        sections {
          id
          collection
          ...PageSections
        }
      }
      translations(filter: { languages_code: { code: { _eq: $lang } } }) {
        title
        slug
        id
        sections {
          id
          collection
          ...PageSections
        }
        seo {
          ...SeoContent
        }
      }
    }
  }
`)

async function queryPetParentsCornerPage(language?: string) {
  const lang = language as LanguageRoute
  const languageCode = LanguageCodes[lang]

  const data = await client.request(
    petParentsCornerPage,
    {
      lang: languageCode,
    },
    requestHeaders,
  )

  return data
}

export default queryPetParentsCornerPage
