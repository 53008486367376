import { Columns, Column } from '@bedrock-layout/primitives'
import { useIsClient } from '@okam/shared-ui/src'
import { useState } from 'react'
import { useMediaQuery } from 'usehooks-ts'
import Button from '../Button'
import VideoCard from '../cards/VideoCard'
import Img from '../Img'
import WysiwygBlock from '../WysiwygBlock'
import type { TVideoBlock } from './interface'
import { getBaseStyle } from './styles'

const FIRST_SHOWN_VIDEOS_AMOUNT = 3 // eslint-disable-line @typescript-eslint/naming-convention

const VideoBlock = (props: TVideoBlock) => {
  const { videos, title, button, backgroundColor } = props

  const [buttonWasPressed, setButtonWasPressed] = useState(false)

  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const { isClient } = useIsClient()
  return isClient ? (
    <Columns
      css={getBaseStyle(backgroundColor ?? 'white')}
      columns={isDesktop ? 3 : 1}
      gutter={isDesktop ? 'size9' : 'size7'}
    >
      {title && (
        <Column span={3} tw="lg:pb-8 text-center font-bold">
          <WysiwygBlock content={title} as="h2" variant="h1" />
        </Column>
      )}
      {videos.slice(0, FIRST_SHOWN_VIDEOS_AMOUNT).map(
        (video) =>
          video && (
            <Column key={`videoCard-${video.component.id}`}>
              <VideoCard tw="" {...video.component}>
                <Img
                  alt={video.thumbnail.description ?? ''}
                  {...video.thumbnail}
                  fill
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  tw="object-cover"
                />
              </VideoCard>
            </Column>
          ),
      )}
      {!buttonWasPressed && button && videos.length > FIRST_SHOWN_VIDEOS_AMOUNT && (
        <Column span={3} tw="flex justify-center pt-8">
          <Button {...button} handlePress={() => setButtonWasPressed(true)}>
            {button?.text}
          </Button>
        </Column>
      )}
      {buttonWasPressed &&
        videos.slice(FIRST_SHOWN_VIDEOS_AMOUNT).map(
          (video) =>
            video && (
              <Column key={`videoCard-${video.component.id}`}>
                <VideoCard tw="" {...video.component}>
                  <Img alt={video.thumbnail.description ?? ''} {...video.thumbnail} fill tw="object-cover" />
                </VideoCard>
              </Column>
            ),
        )}
    </Columns>
  ) : (
    <div />
  )
}

export default VideoBlock
