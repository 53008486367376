import { useQuery } from '@tanstack/react-query'
import type { NavigationsQuery } from 'gql/graphql'
import queryNavigations from 'query/navigations'
import QueryKeys from './settings'

export enum MenuLocations {
  Primary = 'PRIMARY',
  Footer = 'FOOTER',
  Secondary = 'SECONDARY',
}

const useNavigationsQuery = (location: MenuLocations, locale?: string) => {
  return useQuery([`${QueryKeys.GetNavigations}-${location}`], () => queryNavigations(locale, location), {
    select: (data: NavigationsQuery) => data.navigations,
  })
}

export default useNavigationsQuery
