import tw, { css } from 'twin.macro'
import type { ButtonVariant, ButtonStyle } from './interface'

const variants = {
  primary: tw`bg-primary-1 hover:bg-primary-1 active:bg-primary-1`,
  iconOnly: tw`bg-white text-black transform hover:scale-135 active:bg-primary-1`,
  textIcon: tw`bg-primary-1 hover:bg-primary-1 active:bg-primary-1`,
  default: tw`bg-primary-1 hover:bg-primary-1 active:bg-primary-1`,
}

const isHollowStyle = css`
  ${tw`bg-transparent text-black !px-0`}
  &:hover {
    svg {
      ${tw`transform translate-x-2`}
    }
  }
  svg {
    ${tw`transition-transform duration-300 ease-in-out`}
  }
`

const isOutlineStyle = tw`border-2 text-black`

const isLargeRoundedStyle = css`
  ${tw`min-w-36 min-h-36 p-0 rounded-full`}
  span {
    ${tw`max-w-23`}
  }
`

const isDropdownStyle = css`
  ${tw`text-black`}
  &:active {
    svg {
      ${tw`text-white transform origin-center rotate-180`}
    }
  }
  svg {
    ${tw`transition-transform duration-500 ease-in-out`}
  }
`

export const getButtonStyle = (buttonStyle: ButtonStyle, variant: ButtonVariant) => {
  switch (buttonStyle) {
    case 'hollow':
      return isHollowStyle
    case 'outline':
      return isOutlineStyle
    case 'largeRounded':
      return isLargeRoundedStyle
    case 'dropdown':
      return isDropdownStyle
    default:
      return variants[variant]
  }
}

export const buttonSpacing = tw`
  px-7
  flex
  min-h-12
  min-w-12
  items-center
  justify-center
`

export const buttonStyle = tw`
  text-button
  font-body
  text-white
  rounded
  border-transparent border-2
  transition-all
  duration-300
  ease-in-out
  disabled:pointer-events-none
  disabled:opacity-30
  focus:outline-none
`

export const baseStyle = css`
  ${buttonSpacing}
  ${buttonStyle}
  svg {
    ${tw`pointer-events-none`}
  }
`
