import { createCtx } from '@okam/core-lib'
import { useCallback, useMemo, useState } from 'react'
import type { TURLTranslationContext, TURLTranslationProvider } from './interface'

export const [useUrlTranslation, URLTranslationProvider] = createCtx<TURLTranslationContext>()

export function URLTranslationContextProvider({ children, urlTranslation }: TURLTranslationProvider) {
  const [url, setUrl] = useState<string>(urlTranslation)

  const setUrlTranslation = useCallback(
    (obj: string) => {
      setUrl(obj)
    },
    [setUrl],
  )

  const value = useMemo<TURLTranslationContext>(
    () => ({ urlTranslation: url, setUrlTranslation }),
    [url, setUrlTranslation],
  )
  return <URLTranslationProvider value={value}>{children}</URLTranslationProvider>
}
