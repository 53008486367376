import { LanguageCodes, type LanguageRoute } from '@/lib/i18n/settings'
import { graphql } from 'gql'
import { requestHeaders, client } from 'query'

const articles = graphql(/* GraphQL */ `
  query Articles($lang: String, $limit: Int) {
    articles(filter: { status: { _eq: "published" } }, limit: $limit, sort: ["sort", "-publication_date"]) {
      id
      translations(filter: { languages_code: { code: { _eq: $lang } } }) {
        title
        content
        slug
        excerpt
        featuredImage: featured_image {
          filenameDownload: filename_download
          id
          width
          height
          description
        }
      }
      featuredImage: featured_image {
        id
        filenameDownload: filename_download
        width
        height
        description
      }
    }
  }
`)

async function queryArticles(language?: string, limit?: number) {
  const lang = language as LanguageRoute
  const languageCode = LanguageCodes[lang]

  const data = await client.request(
    articles,
    {
      lang: languageCode,
      limit,
    },
    requestHeaders,
  )
  return data
}

export default queryArticles
