import { SelectReactAria } from '@okam/shared-ui'
import type { Key } from 'react'
import type { TSelectFilterProps } from './interface'
import { getButtonStyles, getOptionsColors, getPopoverStyles } from './styles'

const SelectFilter = (props: TSelectFilterProps) => {
  const {
    disabled = false,
    isError = false,
    errorMessage,
    children,
    placeholderLabel,
    bgColor,
    hoverColor,
    iconBgColor,
    variant,
    optionColors = {},
    value,
    selectStyles,
    onSelectionChange,
    popoverStyles,
    shouldPopoverFlip = true,
    allowOutsideScrolling = false,
    ariaLabel,
  } = props

  const handleChange = (changedValue: Key) => {
    if (onSelectionChange) {
      onSelectionChange(changedValue)
    }
  }

  return (
    <div
      css={[
        getButtonStyles(variant, bgColor, hoverColor, iconBgColor),
        getOptionsColors(optionColors, value),
        selectStyles,
      ]}
      tw="flex justify-center min-h-[3.25rem]"
    >
      <SelectReactAria
        id="filter"
        name="filter"
        placeholderLabel={placeholderLabel}
        disabled={disabled}
        isError={isError}
        errorMessage={errorMessage}
        popoverStyles={[...getPopoverStyles(variant, bgColor, hoverColor), ...(popoverStyles ?? [])]}
        icon="ArrowDown"
        selectedKey={value}
        onSelectionChange={handleChange}
        ariaLabel={ariaLabel}
        shouldPopoverFlip={shouldPopoverFlip}
        allowOutsideScrolling={allowOutsideScrolling}
      >
        {children}
      </SelectReactAria>
    </div>
  )
}

export default SelectFilter
