import { useRouter } from 'next/router'

const useProductTypeQueryParams = () => {
  type TUrlQueryParams = {
    slug: string
    productType: string
  }

  const router = useRouter()
  const urlFilterParams = router.query as TUrlQueryParams
  return urlFilterParams
}

export default useProductTypeQueryParams
