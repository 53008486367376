import { Player, Controls } from '@lottiefiles/react-lottie-player'
import { useEffect, useRef } from 'react'
import type { TLottiProps } from './interface'

const LottiePlayer = (props: TLottiProps) => {
  const {
    id,
    src,
    autoplay,
    loop,
    controls,
    hover,
    direction,
    background,
    keepLastFrame,
    speed,
    renderer,
    height,
    width,
  } = props

  const ref = useRef<Player>(null)

  const handleMouseEnter = () => {
    ref.current?.setPlayerDirection(1)
    ref.current?.play()
  }

  const handleMouseLeave = () => {
    ref.current?.setPlayerDirection(-1)
    ref.current?.play()
  }

  useEffect(() => {
    const lottieElement = ref?.current?.container as HTMLDivElement

    if (hover) {
      lottieElement?.addEventListener('mouseenter', handleMouseEnter)
      lottieElement?.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      lottieElement?.removeEventListener('mouseenter', handleMouseEnter)
      lottieElement?.removeEventListener('mouseenter', handleMouseLeave)
    }
  }, [hover])

  return (
    <Player
      ref={ref}
      id={id}
      src={src}
      autoplay={autoplay}
      loop={loop}
      direction={direction}
      background={background}
      keepLastFrame={hover ? true : keepLastFrame}
      speed={speed}
      renderer={renderer}
      style={{ height: `${height}`, width: `${width}` }}
    >
      {controls && <Controls visible buttons={['play', 'repeat', 'frame', 'debug']} />}
    </Player>
  )
}

export default LottiePlayer
