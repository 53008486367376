import React from 'react'
import { styled } from 'twin.macro'
import Heading from '../Heading/index'
import Paragraph from '../Paragraph'
import type { THomepageAnimalChoiceProps } from './interface'
import baseStyle from './styles'

const StyledHomepageAnimalChoice = styled.div(() => [baseStyle])

const HomepageAnimalChoice = (props: THomepageAnimalChoiceProps) => {
  const { titleRegular, titleBold, subTitle, userChoice, children } = props

  return (
    <StyledHomepageAnimalChoice {...props}>
      <div tw="text-center text-black w-full z-10 break-words">
        <Heading variant="h2" as="h2" tw="font-semibold">
          {titleRegular}
          <br />
          <span tw="font-black uppercase">{titleBold}</span>
        </Heading>
      </div>
      <div tw="grid sm:gap-2 grid-cols-2 grid-rows-2 lg:grid-cols-3 lg:grid-rows-none lg:max-w-200 mx-auto justify-center mt-9">
        {children}
      </div>
      <div tw="text-center text-black w-full z-10 break-words mt-32">
        <Heading variant="h3" as="h3">
          <Paragraph tw="font-semibold" variant="h4" as="h4">
            {subTitle}
          </Paragraph>
          <span tw="font-bold">{userChoice}</span>
        </Heading>
      </div>
    </StyledHomepageAnimalChoice>
  )
}

export default HomepageAnimalChoice
