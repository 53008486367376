import { useHtml2React } from '@okam/html2react/provider'
import { Typography } from '@okam/shared-ui/index'

export const Html2React = (props: { content: string }) => {
  const { parseHTMLToReact } = useHtml2React()
  const { content } = props

  return (
    <Typography as="section" variant="p" tw="prose m-auto prose-petstory px-6 md:px-0" {...props}>
      {parseHTMLToReact(content)}
    </Typography>
  )
}
