import type { TTheme } from '@okam/shared-ui'
import { ThemeContextProvider } from '@okam/shared-ui'
import React from 'react'
import tw from 'twin.macro'

const brandTheme: TTheme = {
  typography: ({ variant }) => [
    variant === 'h1' && tw`font-body text-5xl`,
    variant === 'h2' && tw`font-body text-4xl`,
    variant === 'h3' && tw`font-body text-3xl`,
    variant === 'h4' && tw`font-body text-2xl`,
    variant === 'h5' && tw`font-body text-1xl`,
    variant === 'h6' && tw`font-body text-button`,
    variant === 'leading' && tw`font-body text-leading`,
    variant === 'p' && tw`font-body text-paragraph`,
    variant === 'footnote' && tw`font-body text-footnote`,
  ],
  button: ({ variant, buttonStyle }) => [
    tw`text-button !px-8 !min-h-12 !min-w-12 rounded`,
    variant === 'primary' && buttonStyle === 'default' && tw`bg-primary-1 text-white`,
    variant === 'primary' && buttonStyle === 'outline' && tw`border-2 border-primary-1 text-black hover:text-black`,
    variant === 'primary' && buttonStyle === 'hollow' && tw`text-black`,
  ],
}

export const BrandThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <ThemeContextProvider brandTheme={brandTheme}>{children}</ThemeContextProvider>
)

export default brandTheme
