import tw, { css } from 'twin.macro'

const hoverStyles = css`
  &:hover {
    button {
      svg {
        ${tw`transform translate-x-2`}
      }
    }
  }
`

export { hoverStyles }
