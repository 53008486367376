import { Icon } from '@okam/shared-ui/index'
import React from 'react'
import tw from 'twin.macro'
import type { TTextIconPatternProps } from './interface'

const TextIconPattern = (props: TTextIconPatternProps) => {
  const { iconPosition, icon, children } = props
  return (
    <span
      css={
        (iconPosition === 'left' && tw`font-body flex items-center`) ||
        (iconPosition === 'right' && tw`font-body flex flex-row-reverse items-center`) ||
        (iconPosition === 'top' && tw`font-body items-center justify-center flex flex-col`) ||
        (iconPosition === 'bottom' && tw`font-body items-center justify-center flex flex-col-reverse`)
      }
    >
      {icon &&
        (children ? (
          <Icon
            icon={icon}
            css={
              (iconPosition === 'right' && tw`ml-1`) ||
              (iconPosition === 'left' && tw`mr-4`) ||
              (iconPosition === 'top' && tw`mb-3`) ||
              (iconPosition === 'bottom' && tw`mt-3`)
            }
          />
        ) : (
          <Icon icon={icon} />
        ))}
      {iconPosition === 'right' && children}
      {iconPosition === 'left' && children}
      {iconPosition === 'top' && children}
      {iconPosition === 'bottom' && children}
    </span>
  )
}

export default TextIconPattern
