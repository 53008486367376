import { client, requestHeaders } from 'query'
import { graphql } from '../../../gql'

export const lottie = graphql(/* GraphQL */ `
  query lottie($filename: String) {
    animations(filter: { lottie_file: { filename_download: { _eq: $filename } } }) {
      typename: __typename
      animal {
        id
      }
      lottie: lottie_file {
        id
        filename: filename_download
      }
      id
    }
  }
`)

async function queryLottie(filename: string) {
  const data = await client.request(
    lottie,
    {
      filename,
    },
    requestHeaders,
  )

  return data
}

export default queryLottie
