import { useAnalytics } from 'use-analytics'
import analytics from 'gtm-analytics/analytics.config'
import type EventMap from 'gtm-analytics/events'

export function useTrack<K extends keyof EventMap>(eventName: K, props: EventMap[K]): void {
  const { track } = useAnalytics()

  track(eventName, props)
}

export function trackEvent<K extends keyof EventMap>(eventName: K, props: EventMap[K]): void {
  analytics.track(eventName, props)
}

export function genTrackEvent<K extends keyof EventMap>(eventName: string, props: EventMap[K]): void {
  analytics.track(eventName, props)
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function unTypedTrackEvent(eventName: string, props: any): void {
  analytics.track(eventName, props)
}
