import DOMPurify from 'isomorphic-dompurify'
import { css } from 'twin.macro'
import BrandTypography from '../BrandTypography'
import type TWysiwygBlockProps from './interface'

const customStyle = css`
  ul,
  ol {
    list-style: disc;
    margin-left: 1.25rem;
  }
`

const WysiwygBlock = ({ content, variant = 'p', as = 'div', ...rest }: TWysiwygBlockProps) => (
  <BrandTypography
    css={customStyle}
    as={as}
    variant={variant}
    {...rest}
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(content, {
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
      }),
    }}
  />
)

export default WysiwygBlock
