import { isEmpty } from 'lodash'
import React from 'react'
import { styled, css } from 'twin.macro'
import type { TFoldProps, TFoldBackgroundProps, TFoldWrapperProps } from './interface'
import FoldStyle, { getFoldBackgroundStyle, getWrapperStyle } from './styles'

const StyledFold = styled.section(() => [FoldStyle])

const StyledFoldBackground = styled.div(({ backgroundColor }: TFoldBackgroundProps) => [
  getFoldBackgroundStyle(backgroundColor),
])

const StyledWrapper = styled.div(() => [getWrapperStyle()])

const Fold = ({ children, ...props }: TFoldProps) => <StyledFold {...props}>{children}</StyledFold>

const FoldBackground = ({ children, ...props }: TFoldBackgroundProps) => (
  <StyledFoldBackground {...props}>{children}</StyledFoldBackground>
)

const FoldWrapper = ({ children, backgroundColor, background, customCSS, fold = {}, ...props }: TFoldWrapperProps) => (
  <Fold
    {...fold}
    css={css`
      ${customCSS}
    `}
  >
    {(isEmpty(background) || typeof backgroundColor === 'string') && (
      <FoldBackground backgroundColor={backgroundColor} {...background} />
    )}
    <StyledWrapper {...props}>{children}</StyledWrapper>
  </Fold>
)

export default FoldWrapper
