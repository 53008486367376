import { Img } from '@okam/brand-ui'
import type { TProcessingInstruction } from '@okam/html2react'
import { extractRelativePath, parseHtmlStyle } from '@okam/html2react'
import React from 'react'

const replaceImage: TProcessingInstruction = {
  replaceChildren: false,
  shouldProcessNode(node) {
    return node.type != null && node.type === 'tag' && node.name === 'img'
  },

  processNode(node) {
    const { attribs } = node
    const className = attribs.class
    const { width, height, src, alt } = attribs as React.ImgHTMLAttributes<HTMLImageElement>

    return (
      <Img
        key={src}
        style={parseHtmlStyle(attribs.style)}
        width={Number(width)}
        height={Number(height)}
        src={extractRelativePath(src ?? '')}
        alt={alt!}
        className={className}
        objectFit="cover"
        layout="intrinsic"
        priority
      />
    )
  },
}

export default replaceImage
