import { Global } from '@emotion/react'
import React from 'react'
import tw, { css, GlobalStyles as BaseStyles } from 'twin.macro'

const customStyles = css`
  * {
    ${tw`outline-none`}
  }
  body {
    ${tw`antialiased`}
  }
  @font-face {
    font-family: 'Museo Sans Rounded';
    src: url('/assets/fonts/MuseoSansRounded-300.woff2') format('woff2'),
      url('/assets/fonts/MuseoSansRounded-300.woff') format('woff');
    font-weight: thin;
    font-weight: 100;
    font-weight: extra-light;
    font-weight: 200;
    font-weight: light;
    font-weight: 300;
    font-weight: normal;
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Museo Sans Rounded';
    src: url('/assets/fonts/MuseoSansRounded-500.woff2') format('woff2'),
      url('/assets/fonts/MuseoSansRounded-500.woff') format('woff');
    font-weight: medium;
    font-weight: 500;
    font-style: normal;
    font-weight: semi-bold;
    font-weight: 600;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Museo Sans Rounded';
    src: url('/assets/fonts/MuseoSansRounded-700.woff2') format('woff2'),
      url('/assets/fonts/MuseoSansRounded-700.woff') format('woff');
    font-weight: bold;
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Museo Sans Rounded';
    src: url('/assets/fonts/MuseoSansRounded-900.woff2') format('woff2'),
      url('/assets/fonts/MuseoSansRounded-900.woff') format('woff');
    font-weight: extra-bold;
    font-weight: 800;
    font-weight: black;
    font-weight: 900;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Museo Sans Rounded';
    src: url('/assets/fonts/MuseoSansRounded-900Italic.woff2') format('woff2'),
      url('/assets/fonts/MuseoSansRounded-900Italic.woff') format('woff');
    font-weight: extra-bold;
    font-weight: 800;
    font-weight: black;
    font-weight: 900;
    font-style: italic;
    font-display: block;
  }
`

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
)

export default GlobalStyles
