import { useQuery } from '@tanstack/react-query'
import type { ColorSchemeFragment, ProductsArchivePageQuery } from 'gql/graphql'
import queryProductsArchivePage from 'query/products-archive'

const useProductsArchivePage = (
  queryKey: string,
  locale: string,
  onSuccess: (colorScheme: ColorSchemeFragment | null | undefined) => void,
) => {
  const productsArchivePageQuery = useQuery([queryKey], () => queryProductsArchivePage(locale), {
    select: (data: ProductsArchivePageQuery) => data.pages[0],
    onSuccess: (data) => {
      onSuccess(data.colorScheme)
    },
  })

  return productsArchivePageQuery
}

export default useProductsArchivePage
