import type { TButtonProps } from '@okam/brand-ui/components/Button/interface'
import { Button } from '@okam/brand-ui/index'
import React from 'react'
import { css } from 'twin.macro'
import { unTypedTrackEvent } from './trackEvent'

type TTrackingButtonProps = TButtonProps & {
  trackEventArgs: {
    eventName: string
    args: any /* eslint-disable-line @typescript-eslint/no-explicit-any */
  }
  useMarginAuto?: boolean
}

const TrackingButton = (props: TTrackingButtonProps) => {
  const { trackEventArgs, useMarginAuto = true, children, ...rest } = props

  return (
    <Button
      handlePress={() => unTypedTrackEvent(trackEventArgs.eventName, trackEventArgs.args)}
      css={css`
        ${useMarginAuto && 'margin: auto;'}
      `}
      tw="flex justify-center items-center text-center"
      {...rest}
    >
      {children}
    </Button>
  )
}

export default TrackingButton
