import { type LanguageRoute, LanguageCodes } from '@/lib/i18n/settings'
import { client, requestHeaders } from 'query'
import { graphql } from '../../../gql'

export const animations = graphql(/* GraphQL */ `
  query animations($forProductGrid: Boolean, $lang: String) {
    animations(
      filter: {
        show_on_product_grid: { _eq: $forProductGrid }
        _or: [{ _and: [{ language: { code: { _eq: $lang } } }] }, { _and: [{ language: { code: { _null: true } } }] }]
      }
    ) {
      id
      typename: __typename
      animal {
        id
      }
      lottieFile: lottie_file {
        id
        filenameDownload: filename_download
      }
    }
  }
`)

async function queryAnimalLotties(forProductGrid: boolean, language: string) {
  const lang = language as LanguageRoute
  const languageCode = LanguageCodes[lang]

  const data = await client.request(
    animations,
    {
      forProductGrid,
      lang: languageCode,
    },
    requestHeaders,
  )

  return data
}

export default queryAnimalLotties
