import { useIsClient } from '@okam/shared-ui'
import React from 'react'
import { styled } from 'twin.macro'
import { useMediaQuery } from 'usehooks-ts'
import Heading from '../Heading/index'
import type { THomepageInstagramProps } from './interface'
import baseStyle from './styles'

const StyledHomepageInstagram = styled.div(() => [baseStyle])

const HomepageInstagram = (props: THomepageInstagramProps) => {
  const { titleRegular, titleBold, instagramButton, children } = props

  const { isClient } = useIsClient()
  const isNotSmallMobile = useMediaQuery('(min-width: 420px)') && isClient

  return (
    <StyledHomepageInstagram {...props}>
      <div tw="absolute text-center text-black w-full z-10 break-words">
        <Heading variant="h2" as="h2" tw="font-semibold">
          {titleRegular}
        </Heading>
        <Heading variant={isNotSmallMobile ? 'h2' : 'h6'} as="h3" tw="font-black uppercase">
          {titleBold}
        </Heading>
      </div>
      <div>{children}</div>
      <div tw="flex justify-center absolute w-full bottom-1/4 md:bottom-[10%] lg:bottom-1/4">{instagramButton}</div>
    </StyledHomepageInstagram>
  )
}

export default HomepageInstagram
