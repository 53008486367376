import { cache } from '@emotion/css'
import { CacheProvider } from '@emotion/react'
import { MDXProvider } from '@mdx-js/react'
import { BrandThemeProvider } from '@okam/brand-ui'
import { Html2ReactProvider } from '@okam/html2react/provider'
import { IsClientContextProvider } from '@okam/shared-ui'
import { SSRProvider } from '@react-aria/ssr'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { I18nProvider } from 'react-aria'
import { AnalyticsProvider } from 'use-analytics'
import analytics from 'gtm-analytics/analytics.config'
import GlobalStyles from 'styles/GlobalStyles'
import { DirectusContextProvider } from './lib/directus'
import { initialInstructions } from './lib/html2react'
import { mdxComponents } from './lib/next-mdx-remote'
import { ThemeContextProvider } from './providers/Color'
import { URLTranslationContextProvider } from './providers/UrlTranslation'

export const AppProviders = ({ children, locale }: { children: React.ReactNode; locale?: string }) => {
  const [queryClient] = React.useState(() => new QueryClient())

  return (
    <ThemeContextProvider colorScheme={{ background: '#FFFFFF', accent: '#FFFFFF', title: 'null' }}>
      <URLTranslationContextProvider urlTranslation="">
        <QueryClientProvider client={queryClient}>
          <SSRProvider>
            <IsClientContextProvider>
              <I18nProvider locale={locale}>
                <CacheProvider value={cache}>
                  <AnalyticsProvider instance={analytics}>
                    <GlobalStyles />
                    <Html2ReactProvider initialInstructions={initialInstructions}>
                      <MDXProvider components={mdxComponents}>
                        <DirectusContextProvider locale={locale}>
                          <BrandThemeProvider>{children}</BrandThemeProvider>
                        </DirectusContextProvider>
                      </MDXProvider>
                    </Html2ReactProvider>
                  </AnalyticsProvider>
                </CacheProvider>
              </I18nProvider>
            </IsClientContextProvider>
          </SSRProvider>
        </QueryClientProvider>
      </URLTranslationContextProvider>
    </ThemeContextProvider>
  )
}
