import tw, { css } from 'twin.macro'

export const imgStyle = css`
  img:nth-of-type(1) {
    ${tw`opacity-60 min-h-186 min-w-186 2xl:min-h-256 2xl:min-w-256 -bottom-1/2 -right-[25rem] sm:-right-1/3 2xl:-bottom-1/3 2xl:-right-1/10`}
  }
  img:nth-of-type(2),
  .lf-player-container {
    ${tw`absolute bottom-4 md:bottom-8 right-0`}
  }
  #lottie {
    ${tw`!w-[300px] !h-[300px] xs:!w-[400px] xs:!h-[400px] md:!w-[500px] md:!h-[500px] lg:!w-[600px] lg:!h-[600px] xl:!w-[700px] xl:!h-[700px] 3xl:!w-[900px] 3xl:!h-[900px] 4xl:!w-[1000px] 4xl:!h-[1000px]`}
  }
`
