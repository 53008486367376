import React from 'react'
import { useListBox, useOption } from 'react-aria'
import type { TListBoxProps, TOptionProps } from './Listbox.interface'

const Option = ({ item, state }: TOptionProps) => {
  const ref = React.useRef(null)
  const { optionProps } = useOption({ key: item.key }, state, ref)

  return (
    <li {...optionProps} ref={ref} tw="transition hover:bg-gray-2 w-full px-4">
      {item.rendered}
    </li>
  )
}

const ListBox = (props: TListBoxProps) => {
  const ref = React.useRef(null)
  const { listBoxRef = ref, state } = props
  const { listBoxProps } = useListBox(props, state, listBoxRef)

  return (
    <ul {...listBoxProps} ref={listBoxRef} tw="p-2 my-4 rounded-md border">
      {[...state.collection].map((item) => (
        <Option key={item.key} item={item} state={state} />
      ))}
    </ul>
  )
}

export { ListBox, Option }
