import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import type { AnimationsQuery } from 'gql/graphql'
import queryAnimalLotties from 'query/common/lotties'

const useAnimationsLotties = (queryKey: string, forProductGrid: boolean) => {
  const { locale } = useRouter()

  const animationLotties = useQuery([queryKey], () => queryAnimalLotties(forProductGrid, locale ?? 'fr'), {
    select: (data: AnimationsQuery) => data.animations,
  })

  return animationLotties
}

export default useAnimationsLotties
