import { css } from 'twin.macro'
import Heading from '../../Heading'
import Paragraph from '../../Paragraph'
import type { TTestimonialCardProps } from './interface'
import { imgStyles } from './styles'

const TestimonialCard = (props: TTestimonialCardProps) => {
  const { bgColor, children, text, title, footnote } = props

  return (
    <div
      tw="rounded-4xl w-full"
      css={css`
        background-color: ${bgColor};
        @media only screen and (min-width: 768px) {
          &:nth-of-type(even) > div {
            div {
              img {
                border-radius: 0 0 1.25rem 1.25rem;
              }
            }
            flex-direction: column-reverse;
          }
        }
      `}
    >
      <div tw="rounded-4xl h-full flex flex-col justify-between">
        <div css={imgStyles} tw="relative  [aspect-ratio:216/125]">
          {children}
        </div>
        <div tw="flex flex-col h-full shrink basis-3/12 justify-between px-6 lg:px-8 pt-5 pb-4 lg:pt-7 lg:pb-6">
          <Paragraph variant="h5" as="p" tw="mb-2 font-semibold">
            {text}
          </Paragraph>
          <div tw="mt-10">
            <Heading variant="h4" as="h3" tw="uppercase font-black w-full">
              {title}
            </Heading>
            <Paragraph variant="p">{footnote}</Paragraph>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialCard
