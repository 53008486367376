import { LanguageCodes, type LanguageRoute } from '@/lib/i18n/settings'
import { client, requestHeaders } from 'query'
import { graphql } from '../../gql'

export const navigations = graphql(/* GraphQL */ `
  query Navigations($loc: String, $lang: String) {
    navigations(filter: { location: { _eq: $loc } }) {
      date_created
      frTranslations: translations(filter: { languages_code: { code: { _eq: "fr-FR" } } }) {
        items {
          title
        }
      }
      translations(filter: { languages_code: { code: { _eq: $lang } } }) {
        items {
          id
          has_children
          title
          path
          children {
            id
            title
            path
          }
          page {
            translations(filter: { languages_code: { code: { _eq: $lang } } }) {
              title
              slug
            }
          }
        }
      }
    }
  }
`)

async function queryNavigations(language?: string, location?: string) {
  const lang = language as LanguageRoute
  const languageCode = LanguageCodes[lang]

  const data = client.request(
    navigations,
    {
      lang: languageCode,
      loc: location,
    },
    requestHeaders,
  )

  return data
}

export default queryNavigations
