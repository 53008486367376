import { useQuery } from '@tanstack/react-query'
import type { WhereToFindUsQuery } from 'gql/graphql'
import queryWhereToFindUs from 'query/where-to-find-us'
import QueryKeys from './settings'

const useWhereToFindUs = (locale: string) => {
  const whereToFindUsQuery = useQuery([QueryKeys.GetWhereToFindUsPage], () => queryWhereToFindUs(locale), {
    select: (data: WhereToFindUsQuery) => data.pages[0],
  })

  return whereToFindUsQuery
}

export default useWhereToFindUs
