import { useQuery } from '@tanstack/react-query'
import type { PetParentsCornerPageQuery } from 'gql/graphql'
import queryPetParentsCornerPage from 'query/pet-parents-corner'
import QueryKeys from './settings'

const usePetParentsCornerPage = (locale: string) => {
  const PetParentsCornerPageQuery = useQuery(
    [QueryKeys.GetPetParentsCornerPage],
    () => queryPetParentsCornerPage(locale),
    {
      select: (data: PetParentsCornerPageQuery) => data.pages[0],
    },
  )

  return PetParentsCornerPageQuery
}

export default usePetParentsCornerPage
