import React from 'react'
import { DismissButton, FocusScope, useOverlay } from 'react-aria'
import type { TPopoverProps } from './Popover.interface'

const OutsideScrollPopover = (props: TPopoverProps) => {
  const ref = React.useRef(null)

  const { popoverRef = ref, state, children, popoverStyles, onClose } = props
  const { overlayProps } = useOverlay(
    {
      ...props,
      isOpen: state.isOpen,
      isDismissable: false,
      shouldCloseOnBlur: true,
      onClose,
    },
    popoverRef,
  )

  return (
    <FocusScope>
      <div tw="absolute z-[1000]" {...overlayProps} css={popoverStyles} ref={popoverRef}>
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </FocusScope>
  )
}

export default OutsideScrollPopover
