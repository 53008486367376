import { useEffect } from 'react'
import type { ProductQuery } from 'gql/graphql'
import { trackEvent } from '../trackEvent'

type TProduct = ProductQuery['products'][number] | undefined

export default function useTrackViewItem(product: TProduct, index: number) {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!product) return
      trackEvent('viewItem', {
        event: 'ecom_interaction',
        event_name: 'view_item',
        ecommerce: {
          items: [
            {
              item_id: product?.id ?? '',
              item_name: product?.translations?.[0]?.title ?? '',
              item_category: product?.animals?.id ?? '',
              item_category2: 'adult',
              item_category3: product?.product_type?.id ?? '',
              quantity: 1,
              index,
            },
          ],
        },
      })
    }, 1000)
    return () => clearTimeout(timer)
  }, []) /* eslint-disable-line */
}
