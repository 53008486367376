import React, { Suspense, lazy, useMemo } from 'react'
import { useIsClient } from '../../providers/Client'
import Fallback from '../icons/IconFallback'
import type { TIconProps } from './interface'
import 'twin.macro'

const Icon = (props: TIconProps) => {
  const { icon } = props
  const ImportedIcon = useMemo(() => lazy(() => import(`../icons/${icon || 'ArrowRight'}`)), [icon])

  return (
    <Suspense fallback={<Fallback tw="overflow-visible pointer-events-none" />}>
      <ImportedIcon tw="overflow-visible pointer-events-none" {...props} />
    </Suspense>
  )
}

const FrontendOnly = (props: TIconProps) => {
  const { isClient } = useIsClient()

  if (isClient) {
    return <Icon {...props} />
  }

  return null
}

export default FrontendOnly
