import { type RefObject, useRef, createRef } from 'react'

function useScrollIntoView<T extends Element, U = undefined>(possibleItems?: Array<U> | null) {
  const refs = useRef<RefObject<T>[] | undefined>([])

  if (possibleItems) {
    refs.current = possibleItems?.map((element, i) => refs.current?.[i] ?? createRef<T>())
  }

  const scrollToRef = (itemToScrollTo: number) => {
    if (refs.current) {
      refs.current?.[itemToScrollTo]?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  return [refs, scrollToRef] as const
}

export default useScrollIntoView
