/* eslint-disable no-unused-vars */
import { RadioGroupProvider } from '@okam/shared-ui/src'
import { useRadioGroupState } from '@react-stately/radio'
import { useRadioGroup } from 'react-aria'
import tw from 'twin.macro'
import type { TRadioFilterGroupProps } from './interface'
import { withBgStyles } from './styles'

const RadioFilterGroup = (props: TRadioFilterGroupProps) => {
  const { label, children, bgColor, variant, ariaLabel } = props
  const state = useRadioGroupState(props)
  const { radioGroupProps, labelProps } = useRadioGroup(
    { 'aria-label': ariaLabel ?? 'Radio filter group', ...props },
    state,
  )

  return (
    <div {...radioGroupProps}>
      {label && <h2 {...labelProps}>{label}</h2>}
      <div css={[tw`flex my-3 rounded-full`, variant !== 'default' && withBgStyles(bgColor)]}>
        <RadioGroupProvider state={state}>{children}</RadioGroupProvider>
      </div>
    </div>
  )
}

export default RadioFilterGroup
