import { useQuery } from '@tanstack/react-query'
import type { LottieQuery } from 'gql/graphql'
import queryLottie from 'query/common/lottie'

const useLottie = (queryKey: string, filename: string) => {
  const lottie = useQuery([queryKey], () => queryLottie(filename), {
    select: (data: LottieQuery) => data.animations,
  })

  return lottie
}

export default useLottie
