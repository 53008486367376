import { useRadioGroupCtx } from '@okam/shared-ui'
import { useRef } from 'react'
import { mergeProps, useFocusRing, useRadio, VisuallyHidden } from 'react-aria'
import tw from 'twin.macro'
import Paragraph from '../Paragraph'
import type { TRadioFilterProps } from './interface'
import { getVariantStyles, setBgColors, StyledLabel } from './styles'

const RadioFilter = (props: TRadioFilterProps) => {
  const {
    label,
    value,
    isDisabled,
    isError,
    onChange,
    checked,
    variant = 'default',
    bgColor,
    hoverColor,
    ariaLabel,
  } = props
  const state = useRadioGroupCtx()
  const ref = useRef(null)
  const { inputProps } = useRadio({ 'aria-label': ariaLabel ?? 'Radio filter', ...props }, state!, ref)
  const { isFocusVisible, focusProps } = useFocusRing()
  const isSelected = state!.selectedValue === value

  return (
    <div tw="flex">
      <StyledLabel
        isDisabled={isDisabled}
        isError={isError}
        isSelected={isSelected || checked}
        onClick={onChange}
        css={[
          getVariantStyles(variant),
          setBgColors(variant, bgColor, hoverColor, isSelected),
          isFocusVisible && tw`ring-2 ring-black`,
          tw`flex justify-center items-center text-center`,
        ]}
      >
        <VisuallyHidden>
          <input type="radio" {...mergeProps(inputProps, focusProps)} ref={ref} />
        </VisuallyHidden>
        <Paragraph variant="p" tw="text-button">
          {label}
        </Paragraph>
      </StyledLabel>
    </div>
  )
}

export default RadioFilter
