import tw, { css } from 'twin.macro'

export const withBgStyles = (bgColor: string) => css`
  background-color: ${bgColor};
  div:first-of-type > label {
    ${tw`!rounded-l-full pl-9`}
  }
  div:last-of-type > label {
    ${tw`!rounded-r-full pr-9`}
  }
`
