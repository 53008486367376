/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Key } from 'react'
import tw, { css } from 'twin.macro'
import type { TDropdownVariants } from './interface'

const baseButtonStyles = css`
  button {
    ${tw`p-1.5 min-h-full w-full h-auto text-black text-button rounded-3xl min-w-[19rem] focus:outline-none focus-visible:outline-black`}
    span {
      ${tw`w-full font-body text-button`}
      div {
        ${tw`w-full text-center [padding-left: 1.1875rem] text-button`}
        a {
          span {
            padding-left: 0;
          }
        }
      }
      .flavour-icon {
        ${tw`bg-white/100 w-12 h-12`}
      }
      svg {
        ${tw`transition transform ml-auto bg-white/30 rounded-full w-9.5 h-9.5 [padding: 7px]`}
      }
    }
    &[aria-expanded='true'] {
      ${tw`rounded-b-none`}
      svg {
        ${tw`rotate-180`}
      }
    }
    &:disabled {
      ${tw`opacity-30`}
    }
  }
`

const getButtonVariantStyles = {
  default: css`
    button {
      ${tw`p-1.5`}
    }
  `,
  animals: css`
    button {
      border: none;
      contain: paint;
      ${tw`py-1 min-w-64 sm:min-w-[17rem] lg:min-w-[20rem]`}
      span div {
        ${tw`pl-6`}
      }
    }
  `,
  flavour: css`
    button {
      ${tw`lowercase py-0 pl-0 w-64 sm:w-[350px] lg:w-[400px]`}
      span div {
        ${tw`p-0`}
        span {
          ${tw`pl-6`}
        }
      }
    }
  `,
}

export const getButtonStyles = (
  variant: TDropdownVariants,
  bgColor: string,
  hoverColor: string,
  iconBgColor?: string,
) => [
  baseButtonStyles,
  getButtonVariantStyles[variant],
  css`
    button {
      background-color: ${bgColor};
      &:active,
      &:hover,
      &[aria-expanded='true'] {
        background-color: ${hoverColor};
      }
      &:disabled {
        background-color: ${bgColor};
      }
      ${iconBgColor &&
      css`
        span {
          svg {
            background-color: ${iconBgColor};
          }
        }
      `}
    }
  `,
]

const popoverStyles = {
  default: css`
    ul[role='listbox'] li {
      ${tw`p-3 font-body`}
    }
  `,
  flavour: css`
    ul[role='listbox'] {
      ${tw`min-w-64 sm:w-[350px] lg:w-[400px]`}
      li {
        ${tw`p-0.5 lowercase `}
        &[aria-selected="true"] div div {
          ${tw`bg-white/100`}
        }
      }
    }
  `,
  animals: css`
    ul[role='listbox'] {
      ${tw`w-64 sm:min-w-[17rem] lg:min-w-[20rem]`}
    }
  `,
}

const popoverDefaultStyles = (bgColor: string, hoverColor: string) => css`
  ul[role='listbox'] {
    ${tw`p-0 mt-0 min-w-64 border-none rounded-t-none rounded-b-3xl max-h-64 overflow-y-auto font-bold`};
    background-color: ${bgColor};
    scrollbar-width: none;
    &::-webkit-scrollbar {
      ${tw`hidden`}
    }

    li {
      ${tw`font-body p-0 relative overflow-hidden w-full text-button text-center hover:cursor-pointer`}
      &:last-of-type {
        ${tw`rounded-b-3xl`}
      }
      &:hover,
      &[aria-selected='true'] {
        background-color: ${hoverColor};
      }
    }
  }
`

export const getPopoverStyles = (variant: TDropdownVariants, bgColor: string, hoverColor: string) => [
  popoverDefaultStyles(bgColor, hoverColor),
  popoverStyles[variant],
]

export const getOptionsColors = (options: any, selectedKey: Key | undefined) => {
  if (!selectedKey || !options) return ''
  if (selectedKey && !options[selectedKey]) return ''
  return css`
    button {
      background-color: ${options[selectedKey].bgColor} !important;
      &:hover,
      &[aria-expanded='true'] {
        background-color: ${options[selectedKey].hoverColor} !important;
      }
      span div {
        ${tw`bg-transparent`}
      }
    }
  `
}
