import { type Key } from 'react'

/**
 * @param items Generic array of items that you want to filter
 * @param currentFilterState The current state of your radio filter
 * @param operation The operation you want to perform on your array
 * @returns The items you provided filtered with your function
 */
export default function useArrayOperation<TItem>(
  items: TItem[] | undefined | null,
  currentFilterState: Key,
  operation: {
    type: 'map' | 'filter'
    filterFunction?: (value: TItem, index: number, array: TItem[]) => boolean
    mapFunction?: (value: TItem, index: number, array: TItem[]) => TItem
  },
) {
  if (!items) {
    return null
  }

  if (currentFilterState === 'all') {
    return items
  }

  if (operation.type === 'map') {
    return items?.map(operation.mapFunction ?? ((x) => x))
  }

  if (operation.type === 'filter') {
    return items?.filter(operation.filterFunction ?? ((x) => x))
  }

  return items
}
