import { LanguageCodes, type LanguageRoute } from '@/lib/i18n/settings'
import { client, requestHeaders } from 'query'
import { graphql } from '../../gql'

export const whereToFindUs = graphql(/* GraphQL */ `
  query WhereToFindUs($lang: String) {
    pages(filter: { friendly_id: { _eq: "find-us" } }) {
      translations(filter: { languages_code: { code: { _eq: $lang } } }) {
        title
        heading
        slug
        id
        sections {
          collection
          ...PageSections
        }
        seo {
          ...SeoContent
        }
      }
      colorScheme: color_scheme {
        ...ColorScheme
      }
    }
  }
`)

async function queryWhereToFindUs(language?: string) {
  const lang = language as LanguageRoute
  const languageCode = LanguageCodes[lang]

  const data = await client.request(
    whereToFindUs,
    {
      lang: languageCode,
    },
    requestHeaders,
  )

  return data
}

export default queryWhereToFindUs
