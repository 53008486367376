import { LanguageCodes, type LanguageRoute } from '@/lib/i18n/settings'
import { client, requestHeaders } from 'query'
import { graphql } from '../../gql'

const singlePageSlugInOtherLanguage = graphql(/* GraphQL */ `
  query SinglePageSlugInOtherLanguage($uri: String, $lang: String) {
    pages(filter: { translations: { slug: { _eq: $uri } } }) {
      translations(filter: { languages_code: { code: { _neq: $lang } } }) {
        slug
      }
    }
  }
`)

export const singlePage = graphql(/* GraphQL */ `
  query SinglePage($lang: String, $uri: String) {
    pages(filter: { translations: { slug: { _eq: $uri } } }) {
      friendlyId: friendly_id
      parent {
        translations(filter: { languages_code: { code: { _eq: $lang } } }) {
          slug
          title
        }
      }
      colorScheme: color_scheme {
        ...ColorScheme
      }
      frTranslations: translations(filter: { languages_code: { code: { _eq: "fr-FR" } } }) {
        title
        slug
        id
        heading
        sections {
          collection
          ...PageSections
        }
      }
      translations(filter: { languages_code: { code: { _eq: $lang } } }) {
        title
        slug
        id
        heading
        sections {
          collection
          ...PageSections
        }
        seo {
          ...SeoContent
        }
      }
    }
  }
`)

async function querySinglePage(language?: string, uri?: string) {
  const lang = language as LanguageRoute
  const languageCode = LanguageCodes[lang]

  const dataSlugInOtherLanguage = await client.request(
    singlePageSlugInOtherLanguage,
    {
      uri,
      lang: languageCode,
    },
    requestHeaders,
  )

  const data = await client.request(
    singlePage,
    {
      lang: languageCode,
      uri,
    },
    requestHeaders,
  )

  return { singlePageData: data, urlData: dataSlugInOtherLanguage }
}

export default querySinglePage
