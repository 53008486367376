import { Hydrate } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { AppProps as NextAppProps } from 'next/app'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'
import PageTransition from '@/components/layout/PageTransition'
import MarkerIo from '@/components/MarkerIo'
import nextI18nextConfig from '../../next-i18next.config'
import { AppProviders } from '../AppProviders'
import '@bedrock-layout/css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

/**
 * Local fonts
 * @link https://fontsource.org/docs/guides/nextjs
 */

// Workaround for https://github.com/zeit/next.js/issues/8592
export type AppProps = NextAppProps & {
  /** Will be defined only is there was an error */
  err?: Error
}

/**
 * @link https://nextjs.org/docs/advanced-features/custom-app
 */
const MyApp = (appProps: AppProps) => {
  const { Component, pageProps, err, router } = appProps
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <DefaultSeo
        defaultTitle="Petstory"
        title="Petstory"
        description="Petstory"
        openGraph={{
          type: 'website',
          locale: 'fr_CA',
          url: `${process.env.NEXT_PUBLIC_VERCEL_URL}`,
          siteName: 'Petstory',
        }}
      />
      <AppProviders locale={router.locale}>
        {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
        <Hydrate state={pageProps.dehydratedState}>
          <PageTransition mode="wait">
            <Component key={router.route} {...pageProps} err={err} />
          </PageTransition>
        </Hydrate>
        <ReactQueryDevtools position="bottom-right" />
        <MarkerIo id={process.env.NEXT_PUBLIC_MARKER_IO_ID} />
      </AppProviders>
    </>
  )
}

/**
 * Generally don't enable getInitialProp if you don't need to,
 * all your pages will be served server-side (no static optimizations).
 */
/*
MyApp.getInitialProps = async appContext => {
   // calls page's `getInitialProps` and fills `appProps.pageProps`
   const appProps = await App.getInitialProps(appContext)
   return { ...appProps }
}
*/

export default appWithTranslation(MyApp, {
  ...nextI18nextConfig,
})
