import type { TFormInteractionName } from 'gtm-analytics/events/FormSubmission.interface'
import { trackEvent } from './trackEvent'

const sha256 = async (message: string) => {
  const msgBuffer = new TextEncoder().encode(message)

  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

  const hashArray = Array.from(new Uint8Array(hashBuffer))

  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

const trackFormSubmission = async (userEmailAddress: string, formInteraction: TFormInteractionName) => {
  const hashedEmail = await sha256(userEmailAddress)

  trackEvent('formSubmission', {
    event: 'user_interaction',
    event_name: 'form_submit',
    user: {
      email_address: userEmailAddress,
      hashed_email_address: hashedEmail,
    },
    interaction: {
      form_name: formInteraction,
      form_step: 'confirmation',
    },
  })
}

export default trackFormSubmission
