import { LanguageCodes, type LanguageRoute } from '@/lib/i18n/settings'
import { graphql } from 'gql'
import { requestHeaders, client } from 'query'

const productsSearch = graphql(/* GraphQL */ `
  query ProductsSearch($search: String, $lang: String) {
    productsTranslations: products_translations(
      search: $search
      filter: { _and: [{ languages_code: { code: { _eq: $lang } } }, { title: { _contains: $search } }] }
    ) {
      title
      product: products_id {
        id
        colors: color_scheme {
          background
        }
        featuredImage: featured_image {
          id
          filenameDownload: filename_download
          width
          height
        }
        animals {
          id
          color
          translations(filter: { languages_code: { code: { _eq: $lang } } }) {
            title
          }
        }
        translations(filter: { languages_code: { code: { _eq: $lang } } }) {
          id
          sizes
          subtitle
          title
          slug
        }
      }
    }
  }
`)
/**
 *
 * @param search Search by product title
 * @param language Language code
 * @returns Products data
 */
async function queryProductsBySearch(search: string, language?: string) {
  const lang = language as LanguageRoute
  const languageCode = LanguageCodes[lang]

  const data = await client.request(
    productsSearch,
    {
      search,
      lang: languageCode,
    },
    requestHeaders,
  )
  return data
}

export default queryProductsBySearch
