import type { TProcessingInstruction } from '@okam/html2react'
import { Html2React } from './Html2React'
import replaceHeader from './ReplaceHeader'
import replaceHrefLink from './ReplaceHrefLink'
import replaceImage from './ReplaceImage'

const removeEmptyParagraph: TProcessingInstruction = {
  replaceChildren: false,
  shouldProcessNode(node) {
    return (
      node.type != null &&
      node.type === 'tag' &&
      node.name === 'p' &&
      (Array.isArray(node.children) && node.children.length) === 0
    )
  },
  processNode() {
    return ''
  },
}

export const initialInstructions = [removeEmptyParagraph, replaceHeader, replaceHrefLink, replaceImage]
export { Html2React }
