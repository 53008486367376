import Link from 'next/link'
import React from 'react'
import Button from '../../Button'
import Heading from '../../Heading'
import Paragraph from '../../Paragraph'
import type TPageLinkCardProps from './interface'
import { hoverStyles } from './styles'

const PageLinkCard = (props: TPageLinkCardProps) => {
  const { bgColor, link, linkText, children, title, text, ariaLabel } = props

  return (
    <Link href={link} aria-label={ariaLabel} passHref tw="focus:outline-none">
      <div
        css={[{ backgroundColor: `${bgColor}` }, hoverStyles]}
        tw="rounded-4xl transition duration-300 hover:cursor-pointer mx-5 my-6 hover:-rotate-4"
      >
        <div tw="rounded-4xl h-[30rem] overflow-hidden transition duration-300">
          <div tw="flex flex-col px-6 lg:px-8 pt-6">
            <Heading variant="h4" as="h3" tw="w-full mb-4 font-semibold">
              {title}
            </Heading>
            <Paragraph variant="p" tw="font-light">
              {text}
            </Paragraph>
            <Button tabIndex={-1} buttonStyle="hollow" variant="textIcon" icon="ArrowRight" tw="justify-start pl-0">
              {linkText}
            </Button>
          </div>
          <div tw="max-h-[16.5rem]">{children}</div>
        </div>
      </div>
    </Link>
  )
}

export default PageLinkCard
