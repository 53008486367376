import type { TInternalSearchInteraction } from '../events/InternalSearch.interface'
import { trackEvent } from './trackEvent'

const trackInternalSearch = (interaction: TInternalSearchInteraction) => {
  trackEvent('internalSearch', {
    event: 'user_interaction',
    event_name: 'view_search_results',
    interaction,
  })
}

export default trackInternalSearch
