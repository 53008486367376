import tw, { css, styled } from 'twin.macro'
import type { TRadioFilterStyles } from './interface'

export const baseStyles = css`
  label {
    ${tw`text-black w-full border-none font-semibold font-body py-3 px-7 transition focus:outline-none focus-visible:outline-offset-2 focus-visible:outline-black`}
  }
`

export const setBgColors = (
  variant: 'default' | 'withBg',
  bgColor: string,
  hoverColor: string,
  isSelected: boolean,
) => {
  if (variant === 'default' && !isSelected) {
    return css`
      &:hover {
        background-color: ${bgColor};
      }
    `
  }
  if (isSelected) {
    return css`
      background-color: ${hoverColor};
      &:hover {
        background-color: ${hoverColor};
      }
    `
  }
  return css`
    background-color: ${bgColor};
    &:hover {
      background-color: ${hoverColor};
    }
  `
}

export const StyledLabel = styled.label(({ isDisabled = false }: TRadioFilterStyles) => [
  tw`text-black w-full border-none font-bold py-4 px-7 transition hover:cursor-pointer focus:outline-none focus-visible:outline-offset-2 focus-visible:outline-black`,
  isDisabled && tw`opacity-30`,
])

const variantStyles = {
  default: tw`rounded-full mr-2.5`,
  withBg: tw`w-full mx-0 px-9`,
}

export const getVariantStyles = (variant: 'default' | 'withBg') => {
  return variantStyles[variant]
}
