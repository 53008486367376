import { useQuery } from '@tanstack/react-query'
import type { SinglePageQuery, SinglePageSlugInOtherLanguageQuery } from 'gql/graphql'
import querySinglePage from 'query/single-page'
import QueryKeys from './settings'

const useSinglePage = (locale: string, uri: string, onSuccess?: () => void) => {
  const singlePageQuery = useQuery([QueryKeys.GetSinglePage], () => querySinglePage(locale, uri), {
    select: (data: { singlePageData: SinglePageQuery; urlData: SinglePageSlugInOtherLanguageQuery }) => ({
      ...data.singlePageData.pages?.[0],
      slugInOtherLanguage: data.urlData?.pages?.[0],
    }),
    onSuccess,
  })

  return singlePageQuery
}

export default useSinglePage
