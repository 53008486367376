import tw, { css } from 'twin.macro'

const baseStyle = css`
  ${tw`min-h-160 md:min-h-140 lg:min-h-175 xl:min-h-220 pt-18 md:pt-20 xl:pt-36 relative`}
  img:nth-of-type(1) {
    ${tw`opacity-25 right-1/2 -top-1/10 md:right-1/2 md:-top-2/3 2xl:-top-full`}
  }
`

export default baseStyle
