import { Heading } from '@okam/brand-ui/index'
import { motion } from 'framer-motion'
import React, { type Key, useState, type ForwardedRef } from 'react'
import { useTrackViewItemList } from 'gtm-analytics/tracking/eventHooks'
import { PRODUCT_GRID_LOTTIE_LOCATION_INDEX } from '../../../const'
import FacetFilter from './filters/FacetFilter'
import useArrayOperation from './filters/useArrayOperation'
import ProductGridWithLottie from './ProductGridWithLottie'
import type { TProduct, TProductTypeProps } from './types'

const ProductTypeTitle = ({ title }: { title: string }) => (
  <Heading as="h2" variant="h2" tw="flex justify-center pt-20 pb-4 uppercase font-black">
    {title}
  </Heading>
)

/* eslint-disable-next-line react/display-name */
const ProductType = React.forwardRef((props: TProductTypeProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { id, translations, availableFacets, products, animalType, isCardOpen, setIsCardOpen } = props

  useTrackViewItemList(id, products)

  const typeFacetOptions = availableFacets?.[0]?.productFacetsId?.options

  const [currentFacetFilter, setCurrentFacetFilter] = useState<Key>('all')

  const filteredItems = useArrayOperation<TProduct>(products, currentFacetFilter, {
    type: 'filter',
    filterFunction: (product) => product?.facetOptions?.[0]?.productFacetsItemId?.id === currentFacetFilter,
  })

  const getFacetsAvailable = () => {
    const optionsIds = typeFacetOptions?.map((option) => option?.id)

    const facetsIds = products?.map((product) => {
      return product?.facetOptions?.[0]?.productFacetsItemId?.id
    })

    const commonIds = optionsIds?.filter((item) => facetsIds?.includes(item))

    return typeFacetOptions?.filter((option) => commonIds?.includes(option?.id))
  }

  const filteredFacetsAvailable = getFacetsAvailable()
    ?.map((x) => ({ id: x?.id, name: x?.translations?.[0]?.name }))
    ?.filter((s): s is Record<'name' | 'id', string> => s.id != null && s.name != null)

  return (
    <motion.section
      layout
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: isCardOpen.isOpen ? 1 : 0,
      }}
      initial={{ opacity: isCardOpen.isOpen ? 1 : 0 }}
      tw="z-[1000]"
    >
      {translations?.[0]?.title && (
        <motion.div
          animate={{
            opacity: isCardOpen.isOpen ? 0 : 1,
          }}
          initial={{ opacity: isCardOpen.isOpen ? 0 : 1 }}
          ref={ref}
          tw="scroll-my-40"
        >
          <ProductTypeTitle title={translations?.[0]?.title} />
        </motion.div>
      )}
      {typeFacetOptions && (
        <motion.div
          animate={{
            opacity: isCardOpen.isOpen ? 0 : 1,
          }}
          initial={{ opacity: isCardOpen.isOpen ? 0 : 1 }}
        >
          <FacetFilter
            currentFilter={currentFacetFilter}
            setFilter={setCurrentFacetFilter}
            options={filteredFacetsAvailable}
          />
        </motion.div>
      )}
      {filteredItems && (
        <ProductGridWithLottie
          isCardOpen={isCardOpen}
          setIsCardOpen={setIsCardOpen}
          products={filteredItems}
          animalType={animalType}
          animalLocation={PRODUCT_GRID_LOTTIE_LOCATION_INDEX}
        />
      )}
    </motion.section>
  )
})

export default ProductType
