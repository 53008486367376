import {
  FoldWrapper,
  HomepageAnimalChoice,
  Img,
  LottiePlayer,
  RadioFilter,
  RadioFilterGroup,
  SelectFilter,
  useSticky,
} from '@okam/brand-ui/index'
import { useIsClient } from '@okam/shared-ui'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import React, { type Key, type Ref, useEffect, useState, useRef } from 'react'
import { Item, useListData } from 'react-stately'
import { useIntersection } from 'react-use'
import tw, { css, theme } from 'twin.macro'
import { useMediaQuery } from 'usehooks-ts'
import { trackImportantClick, trackPetPreference } from 'gtm-analytics/tracking'
import { toDirectusUrl } from 'lib/directus'
import { QueryKeys } from 'lib/react-query'
import useLottie from 'lib/react-query/useLottie'
import type { TCategoryFilterProps } from '../types'

const CategoryFilter = ({
  animalFilter,
  setAnimalFilter,
  setFilter,
  currentFilter,
  options,
  isHome = false,
  scrollToRef,
  isCardOpen,
}: TCategoryFilterProps) => {
  const { t } = useTranslation('common')

  const allFilterTranslations = t('FILTER.ALL')
  const allProductsFilterTranslations = t('FILTER.ALL_PRODUCTS')

  const iterableOptions = Array.from(options ?? [])

  const selectableOptions = useListData<Record<'id' | 'name', string>>({
    initialItems: [{ id: 'all', name: allFilterTranslations }, ...iterableOptions],
    initialSelectedKeys: [currentFilter],
    getKey: (item) => item.name,
  })

  const { isClient } = useIsClient()
  const isTablet = useMediaQuery('(min-width: 768px)') && isClient
  const isDesktop = useMediaQuery('(min-width: 1280px)') && isClient

  const intersectionRef = React.useRef(null)
  const onScreen = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.3,
  })

  const [fixedFiltersRef, isSticky] = useSticky()

  const [animalSelectVisibility, setAnimalSelectVisibility] = useState<'shown' | 'hidden'>('hidden')
  const router = useRouter()

  useEffect(() => {
    if (onScreen?.isIntersecting) {
      setAnimalSelectVisibility('hidden')
    } else {
      setAnimalSelectVisibility('shown')
    }
  }, [onScreen])

  const isInitialRender = useRef(false)

  useEffect(() => {
    if (isInitialRender.current) {
      const timer = setTimeout(() => {
        if (scrollToRef) scrollToRef(0)
      }, theme('animation.time.medium'))
      return () => clearTimeout(timer)
    }
    isInitialRender.current = true
    return undefined
  }, [animalFilter, currentFilter]) /* eslint-disable-line */

  const AnimalFilter: Record<Key, { name: string; url: string; card: string }> = {
    dog: {
      name: t('FILTER.DOGS'),
      url: t('URL.DOG_ROUTE'),
      card: t('LOTTIES.DOG_CARD'),
    },
    cat: {
      name: t('FILTER.CATS'),
      url: t('URL.CAT_ROUTE'),
      card: t('LOTTIES.CAT_CARD'),
    },
    all: {
      name: t('FILTER.DOGS_CATS'),
      url: t('URL.DOGS_CATS'),
      card: t('LOTTIES.MY_HEART_SWAYS_CARD'),
    },
  }

  const { data: catCard } = useLottie(QueryKeys.GetCatCard, AnimalFilter.cat.card)
  const { data: dogCard } = useLottie(QueryKeys.GetDogCard, AnimalFilter.dog.card)
  const { data: allCard } = useLottie(QueryKeys.GetAllCard, AnimalFilter.all.card)

  useEffect(() => {
    router.prefetch(AnimalFilter.dog.url)
    router.prefetch(AnimalFilter.cat.url)
    router.prefetch(AnimalFilter.all.url)
  })

  const filterSubtitle = t('TITLE.ARCHIVE', { animal: '' })
  const filterTitleRegular = t('TITLE.ANIMAL_CHOICE_REGULAR')
  const filterTitleBold = t('TITLE.ANIMAL_CHOICE_BOLD')

  const animalFilterVariants = {
    hidden: {
      opacity: 0,
      scale: 0,
    },
    shown: {
      opacity: isCardOpen.isOpen ? 0 : 1,
      scale: isCardOpen.isOpen ? 0 : 1,
    },
  }

  return (
    <>
      {isHome && (
        <div ref={intersectionRef}>
          <FoldWrapper backgroundColor="#FFF8F2">
            <HomepageAnimalChoice
              titleRegular={filterTitleRegular}
              titleBold={filterTitleBold}
              subTitle={filterSubtitle}
              userChoice={AnimalFilter[animalFilter ?? 'all'].name}
            >
              <Link
                tw="max-w-[9rem] md:max-w-[16rem] justify-self-end cursor-pointer w-full -translate-x-6 -rotate-6 md:-translate-x-16 translate-y-8 md:-rotate-12 transition-all"
                href={`${AnimalFilter.cat.url}`}
                scroll={false}
                onClick={() =>
                  trackPetPreference(
                    t('FILTER.CATEGORY_FILTER_KITTY'),
                    t('FILTER.CATEGORY_FILTER_KITTY', { lng: 'fr' }),
                  )
                }
              >
                <LottiePlayer
                  hover
                  id="cat"
                  src={toDirectusUrl(catCard?.[0]?.lottie?.id, catCard?.[0]?.lottie?.filename) ?? ''}
                />
              </Link>
              <Link
                tw="max-w-[9rem] md:max-w-[16rem] row-start-1 row-end-2 col-start-1 col-end-3 justify-self-center w-[50%] lg:w-full lg:order-none lg:col-auto lg:row-auto cursor-pointer"
                href={`${AnimalFilter.all.url}`}
                scroll={false}
                onClick={() =>
                  trackPetPreference(
                    t('FILTER.CATEGORY_FILTER_POOCH_AND_KITTY'),
                    t('FILTER.CATEGORY_FILTER_POOCH_AND_KITTY', { lng: 'fr' }),
                  )
                }
              >
                <LottiePlayer
                  hover
                  id="all"
                  src={toDirectusUrl(allCard?.[0]?.lottie?.id, allCard?.[0]?.lottie?.filename) ?? ''}
                />
              </Link>
              <Link
                tw="max-w-[9rem] md:max-w-[16rem] cursor-pointer w-full translate-x-6 rotate-6 md:translate-x-16 translate-y-8 md:rotate-12 transition-all"
                href={`${AnimalFilter.dog.url}`}
                scroll={false}
                onClick={() =>
                  trackPetPreference(
                    t('FILTER.CATEGORY_FILTER_POOCH'),
                    t('FILTER.CATEGORY_FILTER_POOCH', { lng: 'fr' }),
                  )
                }
              >
                <LottiePlayer
                  hover
                  id="dog"
                  src={toDirectusUrl(dogCard?.[0]?.lottie?.id, dogCard?.[0]?.lottie?.filename) ?? ''}
                />
              </Link>
            </HomepageAnimalChoice>
          </FoldWrapper>
        </div>
      )}
      <motion.div
        ref={fixedFiltersRef as Ref<HTMLDivElement>}
        layout
        layoutRoot
        css={css`
          align-items: ${isSticky ? 'end' : 'center'};
          margin-right: ${isSticky ? '1.5rem' : '0px'};
        `}
        tw="flex flex-col gap-4 md:mr-0 md:items-center xl:flex-row justify-center lg:items-center sticky top-[3.3rem] xl:top-10 z-50 !-translate-y-1/4 xl:!-translate-y-1/2 "
      >
        <motion.div variants={animalFilterVariants} animate={animalSelectVisibility}>
          <SelectFilter
            ariaLabel="Filter"
            id={allProductsFilterTranslations}
            label={allProductsFilterTranslations}
            name={allProductsFilterTranslations}
            placeholderLabel={allProductsFilterTranslations}
            bgColor={theme('colors.primary[1]')}
            hoverColor={theme('colors.primaryHover[1]')}
            variant="animals"
            value={animalFilter}
            allowOutsideScrolling
            onSelectionChange={(value) => {
              trackImportantClick({
                click_type: 'bouton',
                interaction_location: 'page',
                link_url: router.asPath ?? '',
                link_text: typeof value === 'string' ? value : '',
                link_text_fr: typeof value === 'string' ? value : '',
              })
              if (isHome) {
                if (setAnimalFilter) setAnimalFilter(value)
                return
              }
              setAnimalFilter?.(value)
              router.push(AnimalFilter[value].url, undefined, { scroll: false })
            }}
            optionColors={{
              all: {
                bgColor: theme('colors.catAndDog.primary'),
                hoverColor: theme('colors.catAndDog.accent'),
              },
              cat: {
                bgColor: theme('colors.cat.primary'),
                hoverColor: theme('colors.cat.accent'),
              },
              dog: {
                bgColor: theme('colors.dog.primary'),
                hoverColor: theme('colors.dog.accent'),
              },
            }}
          >
            <Item key="all" aria-label="Pitou et minou">
              <div
                css={css`
                  ${tw`p-3 transition`};
                  background-color: ${theme('colors.catAndDog.primary')};
                  &:hover,
                  &:active {
                    background-color: ${theme('colors.catAndDog.accent')};
                  }
                `}
              >
                <Img
                  src="/shared-assets/images/dog-and-cat.png"
                  width={114}
                  height={52}
                  alt="pitou & minou"
                  tw="absolute top-0 -left-2"
                  aria-hidden="true"
                />
                {t('FILTER.CATEGORY_FILTER_POOCH_AND_KITTY')}
              </div>
            </Item>
            <Item key="cat" aria-label="Minou">
              <div
                css={css`
                  ${tw`p-3 transition`};
                  background-color: ${theme('colors.cat.primary')};
                  &:hover,
                  &:active {
                    background-color: ${theme('colors.cat.accent')};
                  }
                `}
              >
                <Img
                  src="/shared-assets/images/cat-3.png"
                  width={90}
                  height={52}
                  alt="minou"
                  tw="absolute bottom-0 -left-2"
                  aria-hidden="true"
                />
                {t('FILTER.CATEGORY_FILTER_KITTY')}
              </div>
            </Item>
            <Item key="dog" aria-label="Pitou">
              <div tw="p-3 transition">
                <Img
                  src="/shared-assets/images/dog-head.png"
                  width={100}
                  height={52}
                  alt="pitou"
                  tw="absolute top-0 -left-1"
                  aria-hidden="true"
                />
                {t('FILTER.CATEGORY_FILTER_POOCH')}
              </div>
            </Item>
          </SelectFilter>
        </motion.div>
        {isTablet ? (
          <motion.div
            tw="self-center"
            variants={{
              shown: isDesktop
                ? { x: '0px', opacity: isCardOpen.isOpen ? 0 : 1 }
                : { y: '0%', opacity: isCardOpen.isOpen ? 0 : 1 },
              hidden: isDesktop
                ? { x: '-170px', opacity: isCardOpen.isOpen ? 0 : 1 }
                : { y: '-90%', opacity: isCardOpen.isOpen ? 0 : 1 },
            }}
            animate={animalSelectVisibility}
          >
            <RadioFilterGroup
              bgColor={theme('colors.primary[1]')}
              hoverColor={theme('colors.primaryHover[1]')}
              variant="withBg"
              value={currentFilter.toString()}
              onChange={(value) => {
                setFilter(value)
                trackImportantClick({
                  click_type: 'ancrage',
                  interaction_location: 'page',
                  link_url: router.asPath ?? '',
                  link_text: value,
                  link_text_fr: value,
                })
              }}
            >
              <RadioFilter
                bgColor={theme('colors.primary[1]')}
                hoverColor={theme('colors.primaryHover[1]')}
                id={allFilterTranslations}
                label={allFilterTranslations}
                name={allFilterTranslations}
                value="all"
                variant="withBg"
              />
              {options?.map((type, index) => {
                return (
                  <RadioFilter
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={`${type?.id}-${index}`}
                    bgColor={theme('colors.primary[1]')}
                    hoverColor={theme('colors.primaryHover[1]')}
                    id={type?.id ?? null}
                    label={type?.name ?? 'Filtre'}
                    name={type?.name ?? 'Filtre'}
                    value={type?.id ?? null}
                    variant="withBg"
                  />
                )
              })}
            </RadioFilterGroup>
          </motion.div>
        ) : (
          <motion.div
            variants={{
              shown: { y: '0%', opacity: isCardOpen.isOpen ? 0 : 1 },
              hidden: { y: '-125%', opacity: isCardOpen.isOpen ? 0 : 1 },
            }}
            animate={animalSelectVisibility}
            tw="w-[19rem]"
          >
            <SelectFilter
              ariaLabel="Filter"
              id="filter"
              name="filter"
              placeholderLabel="filter"
              bgColor={theme('colors.primary[1]')}
              hoverColor={theme('colors.primaryHover[1]')}
              variant="default"
              popoverStyles={[tw`min-w-[19rem]`]}
              value={currentFilter}
              allowOutsideScrolling
              onSelectionChange={(value) => {
                setFilter(value)
                trackImportantClick({
                  click_type: 'ancrage',
                  interaction_location: 'page',
                  link_url: router.asPath ?? '',
                  link_text: typeof value === 'string' ? value : '',
                  link_text_fr: typeof value === 'string' ? value : '',
                })
              }}
              tw="!w-[19rem]"
            >
              {selectableOptions.items.map((item) => (
                <Item key={item.id}>{item.name}</Item>
              ))}
            </SelectFilter>
          </motion.div>
        )}
      </motion.div>
    </>
  )
}

export default CategoryFilter
