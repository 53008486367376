import { useEffect } from 'react'
import type { TProducts } from '@/components/products-listing/types'
import { trackEvent } from '../trackEvent'

export default function useTrackViewItemList(id: string, products: TProducts) {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!products) return
      trackEvent('viewItemList', {
        event: 'ecom_interaction',
        event_name: 'view_item_list',
        ecommerce: {
          items: products.map((product, index) => ({
            item_id: product?.id ?? '',
            item_name: product?.translations?.[0]?.title ?? '',
            item_category: product?.animals?.id ?? '',
            item_category2: 'adult',
            item_category3: id,
            quantity: 1,
            index,
          })),
        },
      })
    }, 1000)
    return () => clearTimeout(timer)
  }, []) /* eslint-disable-line */
}
