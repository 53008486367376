import { useQuery } from '@tanstack/react-query'
import type { ProductQuery, ColorSchemeFragment, SlugInOtherLanguageQuery } from 'gql/graphql'
import queryProduct from 'query/product'
import QueryKeys from './settings'

const useProductQuery = (
  uri: string,
  locale: string,
  onSuccess: (colorScheme: ColorSchemeFragment | null | undefined) => void,
) => {
  return useQuery([QueryKeys.GetProduct, uri], () => queryProduct(uri, locale), {
    select: (data: { productData: ProductQuery; urlData: SlugInOtherLanguageQuery }) => ({
      ...data.productData.products?.[0],
      slugInOtherLanguage: data.urlData.products?.[0],
    }),
    onSuccess: (data) => {
      onSuccess(data.colorScheme)
    },
  })
}

export default useProductQuery
