import type { TImportantClickInteraction } from 'gtm-analytics/events/ImportantClick.interface'
import { trackEvent } from './trackEvent'

const trackImportantClick = (importantClickInteraction: TImportantClickInteraction) => {
  trackEvent('importantClick', {
    event: 'user_interaction',
    event_name: 'click',
    interaction: importantClickInteraction,
  })
}

export default trackImportantClick
