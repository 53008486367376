// import { css } from 'twin.macro'
import type { TTypographyProps, TTypographyToken } from './interface'
import { baseToken } from './tokens'

// const assignToken = (variant: TypographyVariant = 'p') => css`
//   font-family: var(--okam-font-family-display);
//   font-size: var(--okam-font-size-${variant});
// `

const mergeToken = (tokens: TTypographyToken) => ({ ...baseToken, ...tokens })

const typographyStyle = ({ tokens = {} }: TTypographyProps) => {
  return [mergeToken(tokens)]
}

export default typographyStyle
