import { useRouter } from 'next/router'
import { type Key, useEffect, useState } from 'react'
import useProductTypeQueryParams from './useProductTypeQueryParams'

const useCategoryFilter = () => {
  const queryParams = useProductTypeQueryParams()
  const [currentCategoryFilter, setCurrentCategoryFilter] = useState<Key>(queryParams.productType ?? 'all')

  const { isReady } = useRouter()

  useEffect(() => {
    if (!isReady) {
      return
    }
    setCurrentCategoryFilter(queryParams.productType ?? 'all')
  }, [isReady])

  return [currentCategoryFilter, setCurrentCategoryFilter] as const
}

export default useCategoryFilter
