import { LanguageCodes, type LanguageRoute } from '@/lib/i18n/settings'
import { client, requestHeaders } from 'query'
import { graphql } from '../../../gql'

export const videos = graphql(/* GraphQL */ `
  query videos($lang: String) {
    videos {
      id
      translations(filter: { languages_code: { code: { _eq: $lang } } }) {
        title
        externalLink: external_link
        thumbnail {
          description
          filenameDownload: filename_download
          id
        }
      }
    }
  }
`)

async function queryVideos(language?: string) {
  const lang = language as LanguageRoute
  const languageCode = LanguageCodes[lang]

  const data = await client.request(
    videos,
    {
      lang: languageCode,
    },
    requestHeaders,
  )

  return data
}

export default queryVideos
