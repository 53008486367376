import { trackEvent } from './trackEvent'

const trackPetPreference = (petPreference: string, petPreferenceFr: string) => {
  trackEvent('selectPetPreference', {
    event: 'user_interaction',
    event_name: 'select_pet_preference',
    interaction: {
      link_text: petPreference,
      link_text_fr: petPreferenceFr,
    },
  })
}

export default trackPetPreference
