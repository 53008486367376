import {
  Button,
  Heading,
  VideoCard,
  VideoBlock,
  Img,
  LottiePlayer,
  TestimonialCard,
  Paragraph,
  FoldWrapper,
  HomepageHero,
  HomepageCoinHumains,
  PageLinkCard,
  HomepageInstagram,
} from '@okam/brand-ui/index'
import type { TTypographyProps } from '@okam/shared-ui/index'
import React from 'react'
import { css } from 'twin.macro'
import ProductsListing from 'components/products-listing/ProductsListing'
import { TrackingButton } from 'gtm-analytics/tracking'

interface ContainerProps {
  children: React.ReactNode
  css: string
}

const Container = ({ children, css: styles }: ContainerProps) => (
  <div
    css={[
      css`
        ${styles}
      `,
    ]}
  >
    {children}
  </div>
)

interface StyledButtonProps {
  children: React.ReactNode
  css: string
}

const StyledButton = (props: StyledButtonProps) => {
  const { children, css: styles, ...rest } = props
  return (
    <Button
      css={[
        css`
          ${styles}
        `,
      ]}
      {...rest}
    >
      {children}
    </Button>
  )
}

export const mdxComponents = {
  Heading,
  Paragraph,
  Button,
  StyledButton,
  Container,
  LottiePlayer,
  VideoCard,
  TestimonialCard,
  Img,
  FoldWrapper,
  HomepageHero,
  ProductsListing,
  HomepageCoinHumains,
  PageLinkCard,
  HomepageInstagram,
  TrackingButton,
  VideoBlock,
  h1: (props: TTypographyProps) => <Heading variant="h1" as="h1" {...props} />,
  h2: (props: TTypographyProps) => <Heading variant="h2" as="h2" {...props} />,
  h3: (props: TTypographyProps) => <Heading variant="h3" as="h3" {...props} />,
  h4: (props: TTypographyProps) => <Heading variant="h4" as="h4" {...props} />,
  h5: (props: TTypographyProps) => <Heading variant="h5" as="h5" {...props} />,
  h6: (props: TTypographyProps) => <Heading variant="h6" as="h6" {...props} />,
}
