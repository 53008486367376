import { useQuery } from '@tanstack/react-query'
import type { VideosQuery } from 'gql/graphql'
import queryVideos from 'query/common/videos'

const useVideos = (queryKey: string, locale: string) => {
  const articleListingQuery = useQuery([queryKey], () => queryVideos(locale), {
    select: (data: VideosQuery) => data,
  })

  return articleListingQuery
}

export default useVideos
