import tw, { css } from 'twin.macro'

const baseStyles = tw`w-full h-full flex flex-col overflow-hidden transition duration-300 focus:!outline-0 focus-visible:shadow-focus`

const hoverStyles = css`
  &:hover {
    button {
      transform: scale(3.5);

      span {
        transform: scale(0.4);
      }
    }

    img {
      transform: scale(1.1);
    }
  }
  span,
  img {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
  }
`

export default baseStyles
export { hoverStyles }
