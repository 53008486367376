import React, { useMemo } from 'react'
import { useNavigationsQuery, useSettingsQuery, MenuLocations } from '../../react-query'
import type { TDirectusContextProvider, TDirectusContext } from './interface'

const DirectusContext = React.createContext<TDirectusContext>(undefined)

function DirectusContextProvider({ locale, children }: TDirectusContextProvider) {
  const { data: settings } = useSettingsQuery(locale)
  const { data: navigations } = useNavigationsQuery(MenuLocations.Footer, locale)

  const memoizedValue = useMemo(() => ({ settings, navigations }), [settings, navigations])

  return <DirectusContext.Provider value={memoizedValue}>{children}</DirectusContext.Provider>
}

function useDirectus() {
  const context = React.useContext(DirectusContext)
  return context
}

export { DirectusContextProvider, useDirectus }
