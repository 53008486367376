import { createCtx, isHex } from '@okam/core-lib'
import { useCallback, useMemo, useState } from 'react'
import type { TColorPanelContext, TColorProvider, TColorScheme } from './interface'

export const [useColor, ColorProvider] = createCtx<TColorPanelContext>()

export function ThemeContextProvider({ children, colorScheme }: TColorProvider) {
  const [color, setColor] = useState<TColorScheme>(colorScheme)

  const setColorScheme = useCallback(
    (obj: Record<string, unknown>) => {
      const { title, accent, background, outline, backgroundMuted, accentMuted } = obj
      if (typeof title !== 'string' || !isHex(accent) || !isHex(background)) {
        return
      }
      setColor({
        title,
        accent,
        background,
        outline: isHex(outline) ? outline : accent,
        accentMuted: isHex(accentMuted) ? accentMuted : accent,
        backgroundMuted: isHex(backgroundMuted) ? backgroundMuted : background,
      })
    },
    [setColor],
  )

  const value = useMemo<TColorPanelContext>(() => ({ colorScheme: color, setColorScheme }), [color, setColorScheme])
  return <ColorProvider value={value}>{children}</ColorProvider>
}
