import { LanguageCodes, type LanguageRoute } from '@/lib/i18n/settings'
import { graphql } from 'gql'
import { requestHeaders, client } from 'query'

const animalId = graphql(/* GraphQL */ `
  query ProductAnimalId($slug: String) {
    products(filter: { translations: { slug: { _contains: $slug } } }) {
      animals {
        id
      }
    }
  }
`)

const slugInOtherLanguage = graphql(/* GraphQL */ `
  query SlugInOtherLanguage($slug: String, $lang: String) {
    products(filter: { translations: { slug: { _contains: $slug } } }) {
      translations(filter: { languages_code: { code: { _neq: $lang } } }) {
        slug
      }
    }
  }
`)

const product = graphql(/* GraphQL */ `
  query Product($slug: String, $animalId: String, $lang: String = "fr") {
    products(filter: { translations: { slug: { _contains: $slug } } }) {
      id
      animals {
        id
        translations(filter: { languages_code: { code: { _eq: $lang } } }) {
          title
        }
      }
      facet_options {
        product_facets_item_id {
          translations {
            name
          }
          parent {
            id
          }
        }
      }
      flavorIcon: flavor_icon {
        filenameDownload: filename_download
        id
        width
        height
      }
      colorScheme: color_scheme {
        background
        accent
        title
        accentMuted: accent_muted
        backgroundMuted: background_muted
        outline
      }
      backgroundPattern: background_pattern {
        id
        filenameDownload: filename_download
        width
        height
      }
      featuredImage: featured_image {
        id
        filenameDownload: filename_download
        width
        height
        description
      }
      additionalImages: additional_images {
        directusFilesId: directus_files_id {
          id
          filenameDownload: filename_download
          width
          height
          description
        }
      }
      animation {
        lottieFile: lottie_file {
          id
          filenameDownload: filename_download
        }
      }
      translations(filter: { languages_code: { code: { _eq: $lang } } }) {
        title
        slug
        ingredients
        caloryContent: calory_content
        subtitle
        description
        sizes
        flavor
        feedingGuideline: feeding_guideline
        guaranteedAnalysis: guaranteed_analysis
        ingredients
        ressources
        claims
        buyCta: buy_cta
        renCta: buy_cta_rens
        specifications
        seo {
          ...SeoContent
        }
      }
      colorScheme: color_scheme {
        background
        accent
        title
      }
      product_type {
        id
        products(filter: { _and: [{ animals: { id: { _eq: $animalId } } }] }) {
          facet_options {
            product_facets_item_id {
              translations {
                name
              }
              parent {
                id
              }
            }
          }
          frTranslations: translations(filter: { languages_code: { code: { _eq: "fr-FR" } } }) {
            title
            flavor
            slug
            id
          }
          translations(filter: { languages_code: { code: { _eq: $lang } } }) {
            title
            flavor
            slug
            id
          }
          flavorIcon: flavor_icon {
            description
            filenameDownload: filename_download
            title
            id
            width
            height
          }
          animals {
            translations(filter: { languages_code: { code: { _eq: $lang } } }) {
              title
            }
          }
        }
      }
      claims {
        id
        claimsId: claims_id {
          icon {
            description
            filenameDownload: filename_download
            title
            id
            width
            height
          }
          translations(filter: { languages_code: { code: { _eq: $lang } } }) {
            content
          }
        }
      }
      recommendations {
        recommendedProducts: recommended_products {
          productsId: products_id {
            typename: __typename
            id
            colors: color_scheme {
              background
            }
            featuredImage: featured_image {
              id
              filenameDownload: filename_download
              width
              height
            }
            animals {
              id
              color
              translations(filter: { languages_code: { code: { _eq: $lang } } }) {
                title
              }
            }
            translations(filter: { languages_code: { code: { _eq: $lang } } }) {
              id
              sizes
              subtitle
              title
              slug
            }
            facetOptions: facet_options {
              productFacetsItemId: product_facets_item_id {
                id
              }
            }
          }
        }
      }
    }
  }
`)

async function queryProduct(slug: string, language?: string) {
  const lang = language as LanguageRoute
  const languageCode = LanguageCodes[lang]

  const dataAnimalId = await client.request(
    animalId,
    {
      slug,
    },
    requestHeaders,
  )
  const dataSlugInOtherLanguage = await client.request(
    slugInOtherLanguage,
    {
      slug,
      lang: languageCode,
    },
    requestHeaders,
  )

  const data = await client.request(
    product,
    {
      slug,
      animalId: dataAnimalId?.products?.[0]?.animals?.id,
      lang: languageCode,
    },
    requestHeaders,
  )
  return { productData: data, urlData: dataSlugInOtherLanguage }
}

export default queryProduct
