import tw, { css } from 'twin.macro'

const baseStyle = css`
  ${tw`min-h-160 md:min-h-140 lg:min-h-175 xl:min-h-220 py-18 md:py-20 xl:py-36 relative overflow-x-clip`}
  .lf-player-container:nth-of-type(2) {
    ${tw`cursor-pointer transform origin-bottom row-start-1 row-end-2 col-start-1 col-end-3 lg:row-start-auto	lg:row-end-auto lg:col-start-auto	lg:col-end-auto`}
    > div {
      ${tw`!h-42 sm:!h-50 lg:!h-75`}
    }
  }
  .lf-player-container:nth-of-type(3) {
    ${tw`cursor-pointer max-h-42 sm:max-h-50 lg:max-h-75 transform origin-bottom-left rotate-[15deg] justify-self-start row-start-2 row-end-3 lg:row-start-auto lg:row-end-auto`}
    > div {
      ${tw`!h-42 sm:!h-50 lg:!h-75`}
    }
  }
`

export default baseStyle
