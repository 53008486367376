import tw, { css } from 'twin.macro'

export const getBaseStyle = (bacgkroundColor: string) => css`
  ${tw`px-8 py-18 w-full h-full md:px-16`}
  @media screen and (min-width: 1024px) {
    *:nth-of-type(3) {
      transform: scale(1.15);
    }
  }

  background-color: ${bacgkroundColor};
`
