import { trackEvent } from './trackEvent'

const trackContentSharing = (sharingMedium: string) => {
  trackEvent('share', {
    event: 'user_interaction',
    event_name: 'share',
    interaction: {
      link_text: sharingMedium,
      link_text_fr: sharingMedium,
    },
  })
}

export default trackContentSharing
