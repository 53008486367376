const domain = process.env.NEXT_PUBLIC_IMG_DOMAIN ?? ''

/**
 * Converts Directus file id and file name to url, which can be used as `src` prop
 * @param id Directus file ID
 * @param filename Directus file name
 * @returns Directus file url
 */
export const toDirectusUrl = (id?: string, filename?: string) => {
  if (id === undefined || filename === undefined) return undefined
  return new URL(`/assets/${id}/${filename}`, `https://${domain}`).href
}
