import { GraphQLClient } from 'graphql-request'

const endpoint = process.env.NEXT_PUBLIC_GRAPHQL_URL ?? ''
const apiToken = process.env.NEXT_PUBLIC_API_TOKEN ?? ''

export const client = new GraphQLClient(endpoint)

export const requestHeaders = {
  authorization: `Bearer ${apiToken}`,
}
