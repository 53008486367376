import { RadioFilter, RadioFilterGroup, SelectFilter } from '@okam/brand-ui/index'
import { useIsClient } from '@okam/shared-ui'
import { useTranslation } from 'next-i18next'
import { Item, useListData } from 'react-stately'
import tw, { theme } from 'twin.macro'
import { useMediaQuery } from 'usehooks-ts'
import type { TFilterProps } from '../types'

const FacetFilter = ({ currentFilter, setFilter, options }: TFilterProps) => {
  const { t } = useTranslation('common')

  const allFilterTranslations = t('FILTER.ALL')

  const { isClient } = useIsClient()
  const isDesktop = useMediaQuery('(min-width: 1280px') && isClient

  const iterableOptions = Array.from(options ?? [])

  const selectableOptions = useListData<Record<'id' | 'name', string>>({
    initialItems: [{ id: 'all', name: allFilterTranslations }, ...iterableOptions],
    initialSelectedKeys: [currentFilter],
    getKey: (item) => item.name,
  })

  if (isDesktop) {
    return (
      <div tw="flex justify-center">
        <RadioFilterGroup
          tw="flex justify-center"
          bgColor={theme('colors.primary[1]')}
          hoverColor={theme('colors.primaryHover[1]')}
          variant="default"
          defaultValue="all"
          value={currentFilter.toString()}
          onChange={(value) => setFilter(value)}
        >
          <RadioFilter
            bgColor={theme('colors.primary[1]')}
            hoverColor={theme('colors.primaryHover[1]')}
            id={allFilterTranslations}
            label={allFilterTranslations}
            name={allFilterTranslations}
            value="all"
            variant="default"
          />
          {options?.map((facet) => {
            return (
              <RadioFilter
                key={facet.id}
                bgColor={theme('colors.primary[1]')}
                hoverColor={theme('colors.primaryHover[1]')}
                id={facet.id}
                label={facet.name}
                name={facet.name}
                value={facet.id}
                variant="default"
              />
            )
          })}
        </RadioFilterGroup>
      </div>
    )
  }

  return (
    <div tw="flex justify-center my-3">
      <SelectFilter
        shouldPopoverFlip
        allowOutsideScrolling
        id="filter"
        name="filter"
        placeholderLabel="filter"
        tw="flex justify-center"
        popoverStyles={[tw`min-w-[19rem]`]}
        bgColor={theme('colors.primary[1]')}
        hoverColor={theme('colors.primaryHover[1]')}
        variant="default"
        value={currentFilter}
        onSelectionChange={(value) => setFilter(value)}
      >
        {selectableOptions.items.map((item) => (
          <Item key={item.id}>{item.name}</Item>
        ))}
      </SelectFilter>
    </div>
  )
}

export default FacetFilter
