import { Cover } from '@bedrock-layout/primitives'
import React from 'react'
// import { styled } from 'twin.macro'
import Heading from '../Heading/index'
import Paragraph from '../Paragraph/index'
import type { THomepageHeroProps } from './interface'
import { imgStyle } from './styles'

// const StyledHomepageFeaturedContent = styled.div(() => [baseStyle])

const HomepageHero = (props: THomepageHeroProps) => {
  const { title, text, children } = props

  return (
    <Cover
      minHeight={1080}
      tw="bg-[#E6F2FA] overflow-hidden !block md:!flex relative !min-h-175 2xl:!min-h-225 3xl:min-h-270"
    >
      <div tw="grid md:grid-cols-2 text-black pt-32 sm:pt-40 mx-auto px-6 2xl:px-10 3xl:px-14 max-w-[1920px]">
        <div tw="flex flex-col z-20">
          <Paragraph variant="leading" as="p" tw="mb-4 max-w-108 font-semibold">
            {text}
          </Paragraph>
          <Heading variant="h1" as="h1" tw="font-semibold">
            {title}
          </Heading>
        </div>
        <div css={[imgStyle]}>{children}</div>
      </div>
    </Cover>
  )
}

export default HomepageHero
