import Link from 'next/link'
import Button from '../../Button'
import Heading from '../../Heading'
import type { TVideoCardProps } from './interface'
import { hoverStyles, imgStyles } from './styles'

const VideoCard = (props: TVideoCardProps) => {
  const { bgColor = 'transparent', link, children = '', title, ariaLabel, ...rest } = props

  return (
    <Link href={link} aria-label={ariaLabel} passHref {...rest} target="_blank" rel="noopener noreferrer">
      <div
        css={[{ backgroundColor: `${bgColor}` }, hoverStyles, imgStyles]}
        tw="relative rounded-4xl hover:cursor-pointer"
      >
        <div tw="relative overflow-hidden">
          {children && <div tw="relative aspect-video">{children}</div>}
          <Button tabIndex={-1} aria-hidden="true" variant="iconOnly" icon="Play" tw="absolute left-8 bottom-4" />
        </div>
        <Heading variant="h5" as="h3" tw="px-6 lg:px-8 pt-5 lg:pt-7 font-semibold">
          {title}
        </Heading>
      </div>
    </Link>
  )
}

export default VideoCard
