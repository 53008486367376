import React, { useRef } from 'react'
import { HiddenSelect, usePress, useSelect } from 'react-aria'
import { useSelectState } from 'react-stately'
import { ButtonWithForwardRef } from '../../../../Button'
import type { TSelectProps } from '../../Select.interface'
import { ListBox } from '../Listbox'
import OutsideScrollPopover from '../OutsideScrollPopover'
import Popover from '../Popover'
import type { TSelectReactAriaProps } from './SelectReactAria.interface'

const SelectReactAria = (props: TSelectReactAriaProps<TSelectProps>) => {
  const {
    name,
    placeholderLabel,
    disabled,
    isError,
    errorMessage,
    icon,
    popoverStyles,
    shouldPopoverFlip = true,
    allowOutsideScrolling = false,
    selection = 'single',
    ariaLabel,
  } = props
  const fieldRef = useRef<HTMLButtonElement & HTMLAnchorElement>(null)
  const state = useSelectState(props)
  const { triggerProps, menuProps } = useSelect(
    { 'aria-label': ariaLabel ?? 'Dropdown select', ...props },
    state,
    fieldRef,
  )
  const { pressProps } = usePress({
    onPressStart: () => {
      if (state.isOpen) {
        state.close()
        state.setOpen(false)
        return
      }
      state.open()
      state.setOpen(true)
    },
  })

  const { onPress, onPressStart, ...restOfTriggerProps } = triggerProps

  return (
    <div tw="inline-block relative w-full" tabIndex={-1}>
      <HiddenSelect state={state} label={ariaLabel} triggerRef={fieldRef} name={name} isDisabled />
      <div>
        <ButtonWithForwardRef
          {...restOfTriggerProps}
          ref={fieldRef}
          buttonStyle="default"
          variant="primary"
          icon={icon ?? 'ChevronDown'}
          disabled={disabled}
          {...pressProps}
        >
          <div>{state.selectedItem ? state.selectedItem.rendered : placeholderLabel}</div>
        </ButtonWithForwardRef>
        {state.isOpen &&
          (allowOutsideScrolling ? (
            <OutsideScrollPopover
              state={state}
              triggerRef={fieldRef}
              placement="bottom start"
              popoverStyles={popoverStyles}
              shouldFlip={shouldPopoverFlip}
              isNonModal
              onClose={state.close}
            >
              <ListBox {...menuProps} selectionMode={selection} state={state} />
            </OutsideScrollPopover>
          ) : (
            <Popover
              state={state}
              triggerRef={fieldRef}
              placement="bottom start"
              popoverStyles={popoverStyles}
              shouldFlip={shouldPopoverFlip}
            >
              <ListBox {...menuProps} selectionMode={selection} state={state} />
            </Popover>
          ))}
      </div>
      {isError && <span tw="text-sm text-red-600 px-6">{errorMessage}</span>}
    </div>
  )
}

export default SelectReactAria
