import tw, { css } from 'twin.macro'

const hoverStyles = css`
  &:hover {
    button {
      transform: scale(1.25);
      span {
        transform: scale(0.75);
      }
    }
  }
  span {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
  }
`

const imgStyles = css`
  img {
    ${tw`rounded-4xl`}
  }
`

export { hoverStyles, imgStyles }
