import { Grid } from '@bedrock-layout/primitives'
import React from 'react'
import { styled } from 'twin.macro'
import Button from '../Button'
import Heading from '../Heading'
import type { THomepageCoinHumainsProps } from './interface'
import baseStyle from './styles'

const StyledHomepageCoinHumains = styled.div(() => [baseStyle])

const HomepageCoinHumains = (props: THomepageCoinHumainsProps) => {
  const { titleRegular, titleBold, link, ariaLabel, children, ...rest } = props

  return (
    <StyledHomepageCoinHumains {...rest}>
      <div tw="text-black w-full z-10 break-words flex items-end">
        {(titleRegular || titleBold) && (
          <Heading variant="h2" as="h2" tw="font-semibold">
            {titleRegular}
            <br />
            <span tw="font-black uppercase">{titleBold}</span>
          </Heading>
        )}
        {link && (
          <div tw="ml-4 mb-2">
            <Button
              as="a"
              role="link"
              variant="iconOnly"
              icon="ArrowRight"
              backgroundColor="#fff"
              href={link}
              aria-label={ariaLabel}
            />
          </div>
        )}
      </div>
      {children && (
        <Grid minItemWidth="20rem" gutter="1rem" tw="mx-auto justify-center mt-9">
          {children}
        </Grid>
      )}
    </StyledHomepageCoinHumains>
  )
}

export default HomepageCoinHumains
