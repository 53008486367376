import { useQuery } from '@tanstack/react-query'
import type { SettingsQuery } from 'gql/graphql'
import querySettings from 'query/settings'
import QueryKeys from './settings'

const useSettingsQuery = (locale?: string) => {
  return useQuery([QueryKeys.GetSettings], () => querySettings(locale), {
    select: (data: SettingsQuery) => data.settings,
  })
}

export default useSettingsQuery
