import { useEffect } from 'react'
import trackInternalSearch from '../trackInternalSearch'

export default function useTrackStoreInternalSearch() {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  /* eslint-disable no-unused-vars */
  const handleSearch = (e: Event) => {
    trackInternalSearch({
      search_type: 'recherche magasin',
    })
  }

  useEffect(() => {
    document.addEventListener('storerocket-storelocator-search', handleSearch)

    // cleanup this component
    return () => {
      document.removeEventListener('storerocket-storelocator-search', handleSearch)
    }
  }, [])
}
