import { useRouter } from 'next/router'
import { useEffect } from 'react'
import analytics from 'gtm-analytics/analytics.config'

const usePageViewAnalytics = () => {
  const { locale } = useRouter()
  useEffect(() => {
    analytics.page({
      event: 'pageview',
      event_name: 'page_view',
      page: {
        page_language: locale,
      },
    })
  }, []) /* eslint-disable-line */
}

export default usePageViewAnalytics
