import { useQuery } from '@tanstack/react-query'
import type { ProductsQuery } from 'gql/graphql'
import queryProducts from 'query/common/products'

const useProducts = (queryKey: string, locale: string, animalType?: string) => {
  const productListingQuery = useQuery([queryKey], () => queryProducts(locale, animalType), {
    select: (data: ProductsQuery) => data,
  })

  return productListingQuery
}

export default useProducts
