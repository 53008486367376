import { Grid } from '@bedrock-layout/primitives'
import type { TProductGridProps } from './interface'
import { gridSizing } from './styles'

const ProductGrid = (props: TProductGridProps) => {
  const { children } = props

  return (
    <Grid gutter="size00" css={gridSizing}>
      {children}
    </Grid>
  )
}

export default ProductGrid
