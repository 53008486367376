import useScrollIntoView from '@okam/brand-ui/helpers/useScrollIntoView'
import { type Key, useState } from 'react'
import tw from 'twin.macro'
import CategoryFilter from './filters/CategoryFilter'
import { useProducts } from '@/lib/react-query' /* eslint-disable-line */
import useArrayOperation from './filters/useArrayOperation'
import { useCategoryFilter } from './hooks'
import ProductType from './ProductType'
import type { TProductType, TProductsListingProps } from './types'

const ProductsListing = ({ queryKey, locale, animalType, onCardOpened, isHome = false }: TProductsListingProps) => {
  const { data: productsData, isSuccess: productsSuccess } = useProducts(queryKey, locale, animalType)

  const [currentCategoryFilter, setCurrentCategoryFilter] = useCategoryFilter()

  const [currentAnimalFilter, setCurrentAnimalFilter] = useState<Key>(animalType ?? 'all')
  const [isCardOpen, updateIsCardOpen] = useState<{ isOpen: boolean; cardOpened: string }>({
    isOpen: false,
    cardOpened: '',
  })

  const setIsCardOpen = (options: { isOpen: boolean; cardOpened: string }) => {
    updateIsCardOpen(options)
    onCardOpened?.()
  }

  const filteredProductsByAnimals = useArrayOperation<TProductType>(productsData?.productTypes, currentAnimalFilter, {
    type: 'map',
    mapFunction: (productType) => {
      const filteredProducts = productType.products?.filter((product) => product?.animals?.id === currentAnimalFilter)
      return { ...productType, products: filteredProducts }
    },
  })

  const filteredProductsTypes = useArrayOperation<TProductType>(filteredProductsByAnimals, currentCategoryFilter, {
    type: 'filter',
    filterFunction: (productType) => productType.id === currentCategoryFilter,
  })

  const [refs, scrollToRef] = useScrollIntoView<HTMLDivElement, TProductType>(filteredProductsTypes)

  const filteredOptions = productsData?.productTypes
    ?.map((x) => ({ id: x?.id, name: x.translations?.[0]?.title }))
    ?.filter((s): s is Record<'name' | 'id', string> => s.id != null && s.name != null)

  return productsSuccess ? (
    <div tw="relative z-[1000] pointer-events-auto" css={isCardOpen.isOpen && tw`z-[1003]`}>
      <CategoryFilter
        isCardOpen={isCardOpen}
        animalFilter={currentAnimalFilter}
        setAnimalFilter={setCurrentAnimalFilter}
        currentFilter={currentCategoryFilter}
        setFilter={setCurrentCategoryFilter}
        options={filteredOptions}
        isHome={isHome}
        scrollToRef={scrollToRef}
      />
      {filteredProductsTypes?.map((type, i) => {
        const productsLength = type.products?.length ?? 0
        return (
          productsLength >= 1 && (
            <ProductType
              setIsCardOpen={setIsCardOpen}
              isCardOpen={isCardOpen}
              key={type.id}
              animalType={typeof currentAnimalFilter === 'string' ? currentAnimalFilter : animalType}
              ref={refs.current?.[i]}
              {...type}
            />
          )
        )
      })}
    </div>
  ) : (
    <div />
  )
}

export default ProductsListing
