/* eslint-disable @typescript-eslint/naming-convention */
enum QueryKeys {
  GetProductsArchivePage = 'get-products-archive-page',
  GetProductsListing = 'get-products-listing',
  GetAnimalProductsListing = 'get-animal-products-listing',
  GetAnimalArchivePage = 'get-animal-archive-page',
  GetProducts = 'get-products',
  GetProduct = 'get-product',
  GetSettings = 'get-settings',
  GetNavigations = 'get-navigations',
  GetHomePage = 'get-home-page',
  GetAnimalLotties = 'get-animal-lotties',
  GetSinglePage = 'get-single-page',
  GetArticle = 'get-article',
  GetArticlesArchivePage = 'get-articles-archive-page',
  GetArticles = 'get-articles',
  GetVideos = 'get-videos',
  GetPetParentsCornerPage = 'get-pet-parents-corner',
  GetWhereToFindUsPage = 'get-find-us',
  GetPetParentsCornerArticles = 'get-pet-parents-corner-articles',
  GetCatCard = 'get-cat-card',
  GetDogCard = 'get-dog-card',
  GetAllCard = 'get-all-card',
}

export default QueryKeys
