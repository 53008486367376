import { useQuery } from '@tanstack/react-query'
import type { ArticlesQuery } from 'gql/graphql'
import queryArticles from 'query/common/articles'

const useArticles = (queryKey: string, locale: string, limit?: number) => {
  const articleListingQuery = useQuery([queryKey], () => queryArticles(locale, limit), {
    select: (data: ArticlesQuery) => data,
  })

  return articleListingQuery
}

export default useArticles
